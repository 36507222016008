import React from "react";
import { Page } from "../../components/page/Page";
import { useNavigate, useParams } from "react-router-dom";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Container } from "../../components/container/Container";
import { Attributepair } from "../../interfaces/Attributepair";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Widget } from "../../components/widget/Widget";
import { Button } from "../../components/button/Button";
import { MdDelete } from "react-icons/md";
import { Input } from "../../components/input/Input";
import { PairSections } from "../../data/PairSections";
import Select from 'react-select'

export const SettingsSinglePairPage = () => {

    const [attributepair, setAttributepair] = React.useState<Attributepair>()
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [selectedSection, setSelectedSection] = React.useState<any>()

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getAttributepair = async () => {
            const result = await getData("/attributepairs/single/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setAttributepair(result)
            setLoading(AppLoadingStates[200].default)
        }

        getAttributepair()
    }, [id])

    const handleChange = async () => {
        const key: any = document.getElementById("newKey")
        const value: any = document.getElementById("newValue")

        if(!(key.value || value.value)) return alert("Ungültige Eingabe")

        console.log(selectedSection)

        const result = await changeData("/attributepairs/" + id, "PUT", {
            key: key.value,
            value: value.value,
            section: (selectedSection ? selectedSection.label : null)
        })  

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        navigate("/settings/pair")
    }

    const handleDelete = async () => {
        const result = await changeData("/attributepairs/" + id, "DELETE", {})
        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        navigate("/settings/pair")
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Attribut bearbeiten</Paragraph>
            <Container display="flex" justify_content="space-between" margin="3em 0 0 0" align_items="flex-start">
                <Widget width="23%">
                    <Paragraph font_size="1.1em" font_weight="600">Attribut löschen ?</Paragraph>
                    <Container margin="1.2em 0 0 0" display="flex" justify_content="center">
                        <Button classes="btn-circle btn btn-error" onClick={() => handleDelete()}>
                            <MdDelete size={24}/>
                        </Button>
                    </Container>
                </Widget>
                <Widget width="73%">
                    {loading === AppLoadingStates[200].default ? (
                        <Container>
                            <Paragraph font_size="0.9em" font_weight="600" color="gray">Sobald sich der Name der Eigenschaft verändert, ändern sich automatisch auch alle bereits hinzugefügten Werte von Kunden mit diesem Attribut</Paragraph>
                            <Paragraph font_size="1em" margin="1em 0 0.9em 0">Eigenschaft</Paragraph>
                            <Input value={attributepair?.key} width="100%" border="1px solid gray" placeholder="Eigenschaft..." padding="0.4em" borderRadius="10px" id="newKey"/>
                            <Paragraph font_size="1em" margin="1em 0 0.9em 0">Bereich</Paragraph>
                            <Select placeholder="Bereich..." options={PairSections} id="sectionInput" onChange={(choice) => setSelectedSection(choice)} />
                            <Paragraph font_size="1em" margin="1em 0 0.9em 0">Wert</Paragraph>
                            <Input value={attributepair?.value} width="100%" border="1px solid gray" placeholder="Eigenschaft..." padding="0.4em" borderRadius="10px" id="newValue"/>
                            <Button classes="btn btn-accent" margin="2em 0 0 0" onClick={() => handleChange()}>Speichern</Button>
                        </Container>
                    ) : (
                        <Paragraph font_size="1em">{loading}</Paragraph>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
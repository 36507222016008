import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { ReportsPage } from './pages/reports/ReportsPage';
import { LoginPage } from './pages/login/LoginPage';
import { CustomersPage } from './pages/customers/CustomerPage';
import { LogoutPage } from './pages/logout/LogoutPage';
import { RegisterPage } from './pages/register/RegisterPage';
import { CreateDashboardForm } from './pages/dashboard/forms/CreateDashboardForm';
import { SingleCustomerPage } from './pages/customers/singlepages/SingleCustomerPage';
import { AccountPage } from './pages/accounts/AccountPage';
import { SingleAccountPage } from './pages/accounts/SingleAccountPage';
import { SettingsPairsPage } from './pages/settings/SettingsPairsPage';
import { SettingsFilterPage } from './pages/settings/SettingsFilterPage';
import { PlactelconfigPage } from './pages/placetel/configs/PlacetelconfigPage';
import { SystemHealthPage } from './pages/customers/singlepages/SystemHealthPage';
import { LiveAgentPage } from './pages/liveagent/LiveagentPage';
import { SingePlacetelConfgPage } from './pages/placetel/SinglePlacetelconfigPage';
import { PlacetelroutingPage } from './pages/placetel/routings/PlacetelroutingPage';
import { CustomerSinglePage } from './pages/customers/singlepages/CustomerSinglePage';
import { HomePage } from './pages/home/HomePage';
import { CustomerAttributEditPage } from './pages/customers/singlepages/CustomerAttributEditPage';
import { SettingsStagesPage } from './pages/settings/SettingsStagesPage';
import { SettingsFormPage } from './pages/settings/SettingsFormsPage';
import { SingleFormPage } from './pages/forms/SingleFormPage';
import { CustomerFormPage } from './pages/forms/CustomerFormPage';
import { SettingsSinglePairPage } from './pages/settings/SettingsSinglePairPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path='/logout' element={<LogoutPage />} />
        <Route path='/register' element={<RegisterPage />} />

        <Route path='/homepage' element={<HomePage />} />

        <Route path='/dashboard' element={<DashboardPage />} />
        <Route path='/dashboard/create' element={<CreateDashboardForm />} />

        <Route path='/berichte' element={<ReportsPage />} />
        
        <Route path='/kunden' element={<CustomersPage />} />
        <Route path='/kunden/single/:id' element={<SingleCustomerPage />} />
        <Route path='/kunden/single/:id/attribute/:attributeId' element={<CustomerAttributEditPage />} />
        <Route path='/kunden/status/:id' element={<SystemHealthPage />} />
        <Route path='/kunden/view/:id' element={<CustomerSinglePage />} />

        <Route path='/accounts' element={<AccountPage />} />
        <Route path='/accounts/single/:id' element={<SingleAccountPage />} />

        <Route path='/settings/pair' element={<SettingsPairsPage />} />
        <Route path='/settings/pair/:id' element={<SettingsSinglePairPage />} />
        <Route path='/settings/filter' element={<SettingsFilterPage />} />
        <Route path='/settings/stage' element={<SettingsStagesPage />} />
        <Route path='/settings/formulare' element={<SettingsFormPage />} />

        <Route path='/formulare/new/:id' element={<SingleFormPage />} />
        <Route path='/formulare/customer' element={<CustomerFormPage />} />

        <Route path='/place/configs' element={<PlactelconfigPage />} />
        <Route path='/place/routing' element={<PlacetelroutingPage />} />
        <Route path='/place/single/:id' element={<SingePlacetelConfgPage />} />

        <Route path='/liveagent' element={<LiveAgentPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { TableColumn } from "../components/table/Table";

export interface Customer {
    id?: number
    name: string
    customernumber: string
    instance?: string
    healthReportId?: number
    createdAt?: Date
    dusername?: string
    dpassword?: string
    email?: string
}

// map to inferface, needed for table

export const customerColumns: TableColumn<Customer>[] = [
    { key: 'name', label: 'Name' },
    { key: 'customernumber', label: 'Kundennummer' },
    { key: 'email', label: "Email"}
];
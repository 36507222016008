import React from "react";
import styles from "./Container.module.scss"

interface props {
    children: React.ReactNode
    margin?: string
    padding?: string
    width?: string
    justify_content?: string
    display?: string
    max_height?: string
    max_width?: string
    gap?: string
    height?: string
    flex_direction?: any 
    align_items?: any
    border_radius?: string
    background?: string
    animate?: boolean
    overflowy?: any
    white_space?: any
    border?: string
    word_break?: any
    border_bottom?: string
    cursor?: string
    overflow?: string
    onClick?: () => void
    border_top_left_radius?: string
    border_top_right_radius?: string
    border_bottom_left_radius?: string
    border_bottom_right_radius?: string
    classes?: string
    flexWrap?: any
    minwidth?: string
    border_top?: string
    border_right?: string
    border_left?: string
    flex?: string
    zebra?: boolean
    minHeight?: string
    position?: any
}

export const Container = ({ children, margin, padding, width, justify_content, display, max_height, gap, height, flex_direction, align_items, border_radius, background, animate, white_space, overflowy, border, word_break, border_bottom, cursor, overflow, onClick, border_top_left_radius, border_top_right_radius, border_bottom_left_radius, border_bottom_right_radius, classes, flexWrap, minwidth, max_width, border_top, border_left, border_right, flex, zebra, minHeight, position}: props) => {

    return (
        <>
        {zebra ? (
            <div className={styles.container__zebra} style={{
                position: position,
                margin: margin,
                padding: padding,
                maxWidth: max_width,
                width: width,
                flex: flex,
                minWidth: minwidth,
                justifyContent: justify_content,
                display: display,
                maxHeight: max_height,
                height: height,
                minHeight: minHeight,
                gap: gap,
                flexDirection: flex_direction,
                alignItems:  align_items ?? "center",
                borderRadius: border_radius,
                backgroundColor: background,
                whiteSpace: white_space,
                overflowY: overflowy,
                border: border,
                wordBreak: word_break,
                borderBottom: (border_bottom  ?? border),
                borderTop: (border_top ?? border),
                cursor: cursor,
                overflow: overflow,
                borderTopLeftRadius: border_top_left_radius ?? border_radius,
                borderTopRightRadius: border_top_right_radius ?? border_radius,
                borderBottomLeftRadius: border_bottom_left_radius ?? border_radius,
                borderBottomRightRadius: border_bottom_right_radius ?? border_radius,
                flexWrap: flexWrap,
                borderLeft: (border_left ?? border),
                borderRight: (border_right ?? border)
            }} onClick={onClick}>
                {children}
            </div>
        ) : (
            <div className={classes} style={{
                position: position,
                margin: margin,
                padding: padding,
                maxWidth: max_width,
                width: width,
                flex: flex,
                minWidth: minwidth,
                justifyContent: justify_content,
                display: display,
                maxHeight: max_height,
                height: height,
                minHeight: minHeight,
                gap: gap,
                flexDirection: flex_direction,
                alignItems:  align_items ?? "center",
                borderRadius: border_radius,
                backgroundColor: background,
                whiteSpace: white_space,
                overflowY: overflowy,
                border: border,
                wordBreak: word_break,
                borderBottom: (border_bottom  ?? border),
                borderTop: (border_top ?? border),
                cursor: cursor,
                overflow: overflow,
                borderTopLeftRadius: border_top_left_radius ?? border_radius,
                borderTopRightRadius: border_top_right_radius ?? border_radius,
                borderBottomLeftRadius: border_bottom_left_radius ?? border_radius,
                borderBottomRightRadius: border_bottom_right_radius ?? border_radius,
                flexWrap: flexWrap,
                borderLeft: (border_left ?? border),
                borderRight: (border_right ?? border)
            }} onClick={onClick}>
                {children}
            </div>
        )}
        </>
    )
}
import { TableColumn } from "../components/table/Table";

export interface Attributepair {
    id?: number
    key: string
    value: string
    comment?: string
    section?: string
    createdBy: string
    createdAt?: string
}

export const attributepairColumns: TableColumn<Attributepair>[] = [
    { key: 'key', label: 'Schlüssel' },
    { key: 'value', label: 'Wert' },
    { key: 'section', label: 'Bereich' },
    { key: 'comment', label: 'Kommentar' },
    { key: 'createdBy', label: 'Erstellt von' },
];
import { channel } from "diagnostics_channel"

interface props {
    borderRadius?: string
    border?: string
    padding?: string
    margin?: string
    height?: string
    width?: string
    fontSize?: string
    background?: string
    id?: string
    children: React.ReactNode
}

export const Select = ({borderRadius, border, padding, margin, height, width, fontSize, background, id, children}: props) => {

    return (
        <select id={id} style={{
            borderRadius: borderRadius,
            border: border,
            padding: padding,
            margin: margin,
            height: height,
            width: width,
            fontSize: fontSize,
            background: background
        }}>
            {children}
        </select>
    )
}
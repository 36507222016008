import { TableColumn } from "../components/table/Table";

export interface Attribute {
    id?: number
    customer_id: number
    key: string,
    value: string
    section?: string
    date?: string
    public: boolean
    createdAt?: string
}

export const attributeColumns: TableColumn<Attribute>[] = [
    { key: 'key', label: 'Eigenschaft' },
    { key: 'value', label: 'Wert' },
    { key: 'public', label: 'Sichtbarkeit Kunde' },
    { key: 'date', label: 'Datum' },
];
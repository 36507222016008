import React from "react";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Widget } from "../../components/widget/Widget";
import { Input } from "../../components/input/Input";
import { FaPlus } from "react-icons/fa";
import { Button } from "../../components/button/Button";
import { Table } from "../../components/table/Table";
import { Dashboard, dashboardColumns } from "../../interfaces/Dashboard";
import { useNavigate } from "react-router-dom";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { IoExitOutline } from "react-icons/io5";

export const DashboardPage = () => {

    const [data, setData] = React.useState<Dashboard[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [searchData, setSearchData] = React.useState<Dashboard[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        const getDashboards = async () => {
            const result = await getData("/dashboards")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setData(result)
            setSearchData(result)
            setLoading(AppLoadingStates[200].default)
        }

        getDashboards()
    }, [])


    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Dashboards</Paragraph>
            <Container margin="3em 0 0 0">
                <Widget display="flex" justify_content="space-between" align_items="center">
                    <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="23vw" id="dashboard-search" />
                    <Container display="flex">
                        <Button classes="btn btn-circle btn-accent" tooltip="Besuche dashboard Plattform" onClick={() => window.open("https://dashboard.digitalxl.io/login")} margin="0 2.2em 0 0">
                            <IoExitOutline size={24}/>
                        </Button>
                        <Button classes="btn btn-square btn-accent" tooltip="Hinzufügen" onClick={() => navigate("/dashboard/create")}>
                            <FaPlus size={22} color="#fff" />
                        </Button>
                    </Container>
                </Widget>
            </Container>
            <Container display="flex" justify_content="space-between" margin="3em 0 0 0" align_items="flex-start">
                <Container width="22%">
                    <Widget>
                        <Paragraph font_size="1.5em">Anzahl an Dashboards</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="3.2em">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-lg btn-circle btn-info" >
                                    <Paragraph font_size="2em" text_align="center">{data.length}</Paragraph>
                                </Button>
                            ) : (
                                <Paragraph font_size="1em">{loading}</Paragraph>
                            )}

                        </Container>
                    </Widget>
                </Container>
                <Container width="74%">
                    <Widget>
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={searchData} columns={dashboardColumns} deleteUrl="/dashboards" allowEllipsis={true} />
                        ) : (
                            <Paragraph font_size="1em">{loading}</Paragraph>
                        )}

                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
import { TableColumn } from "../components/table/Table";

export interface Stage {
    id?: number
    name: string
    color: string
}

export interface StageWithCustomer {
    name: string
    color: string
    customernumber: number
}

export const stageColumns: TableColumn<Stage>[] = [
    { key: 'name', label: 'Stagename' }
];

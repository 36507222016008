import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Navitem } from "./components/Navitem";
import { Widget } from "../../components/widget/Widget";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import { openModal } from "../../helper/OpenModal";
import { Form, formColumns } from "../../interfaces/Form";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Table } from "../../components/table/Table";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Modal } from "../../components/modal/Modal";

export const SettingsFormPage = () => {
    const [forms, setForms] = React.useState<Form[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getForms = async () => {
            const result = await getData("/forms")
            if(!result) return setLoading(AppLoadingStates[500])

            if(isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if(typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setForms(result)
            setLoading(AppLoadingStates[200].default)
        }

        getForms()
    }, [])

    const handleCreate = async () => {
        const name: any = document.getElementById("formNameModal")
        const description: any = document.getElementById("formDescriptionModal")

        if(!(name.value || description.value)) return alert("Ungütlige Eingabe")
        const result = await changeData("/forms",  "POST", {
            "name": name.value,
            "description": description.value,
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Einstellungen</Paragraph>
            <Container margin="2.5em 0 0 0">
                <Container display="flex">
                    <Navitem url="/settings/pair" text="Attribut Paare" active={false} first={true} />
                    <Navitem url="/settings/filter" text="Filter" active={false} />
                    <Navitem url="/settings/stage" text="Stages" active={false} />
                    <Navitem url="/settings/formulare" text="Formulare" active={true} last={true} />
                </Container>
                <Container background="#fff" padding="2em" border_bottom_left_radius="15px" border_bottom_right_radius="5px">
                    <Widget display="flex" align_items="center" justify_content="right">
                        <Button classes="btn btn-square btn-warning" tooltip="Erstellen" onClick={() => openModal("createFormModal")}>
                            +
                        </Button>
                    </Widget>
                    <Modal id="createFormModal" headline="Erstelle ein Formular">
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Formularname</Paragraph>
                        <Input id="formNameModal" placeholder="Name..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%"/>
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Beschreibung (Optional)</Paragraph>
                        <textarea id="formDescriptionModal" placeholder="Beschreibung..." style={{
                            padding: "0.4em",
                            borderRadius: "10px",
                            border: "1px solid gray",
                            width: "100%"
                        }}/>
                        <Button classes="btn btn-warning" onClick={() => handleCreate()} margin="1.3em 0 0 0">Erstellen</Button>
                    </Modal>
                    <Widget margin="2em 0 0 0">
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={forms} columns={formColumns} baseUrl="/formulare/new"/>
                        ) : (
                            <Paragraph font_size="1em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
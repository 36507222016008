import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { FaFileExport, FaPlus, FaSync } from "react-icons/fa";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import { Widget } from "../../components/widget/Widget";
import { useNavigate } from "react-router-dom";
import { Table } from "../../components/table/Table";
import { Customer, customerColumns } from "../../interfaces/Customer";
import { Modal } from "../../components/modal/Modal";
import { openModal } from "../../helper/OpenModal";
import { changeData, getData } from "../../helper/Utils";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { isNumber } from "../../helper/ConvertToStrings";
import { complexSearch, search } from "../../helper/SearchAlgorithm";
import { FormContainer } from "../../helper/GenerateFormInput";
import { Filter } from "../../interfaces/Filter";
import Select from 'react-select'
import { BrevoCampaign } from "../../interfaces/Brevo";
import { Attribute } from "../../interfaces/Attribute";
import { BrevoModal } from "./components/BrevoModal";
import { Stage, StageWithCustomer } from "../../interfaces/Stage";
import { sortDesc } from "../../helper/Sort";
import { CSVLink } from "react-csv";

export const CustomersPage = () => {

    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [customers, setCustomers] = React.useState<Customer[]>([])
    const [searchData, setSearchData] = React.useState<Customer[]>([])
    const [usedQuery, setUsedQuery] = React.useState<string>()

    const [filters, setFilters] = React.useState<any[]>([])
    const [filterLoading, setFilterLoading] = React.useState<string>(AppLoadingStates[0])

    // need this for searching through custom attributes
    const [attributes, setAttributes] = React.useState<Attribute[]>([])

    const [stages, setStages] = React.useState<StageWithCustomer[]>([])
    const [stagesLoading, setStagesLoading] = React.useState<string>(AppLoadingStates[0])

    const navigate = useNavigate()

    React.useEffect(() => {
        const getCustomers = async () => {
            const result = await getData("/customers")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                if(result === 403) return navigate("/")

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCustomers(result)
            setSearchData(result)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomers()
    }, [])

    React.useEffect(() => {
        const getFilters = async () => {
            const result = await getData("/filters/customer")
            if (!result) return setFilterLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setFilterLoading(loadingState)

                return setFilterLoading(AppLoadingStates[500])
            }

            setFilters(result.map((item: Filter) => ({
                value: item.query,
                label: item.name
            })))
            setFilterLoading(AppLoadingStates[200].default)
        }

        getFilters()
    }, [])

    React.useEffect(() => {
        const getAttributes = async () => {
            const result = await getData("/attributes")
            if (!result) return alert("Ein Fehler ist beim fetchen der Custom-Attributes aufgetreten!")

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return alert("Ein Fehler ist beim fetchen der Custom-Attributes aufgetreten!")

                return alert("Ein Fehler ist beim fetchen der Custom-Attributes aufgetreten!")
            }

            setAttributes(result)
        }

        getAttributes()

    }, [])

    React.useEffect(() => {
        const getStages = async () => {
            const result = await getData("/stages/customers/all")
            if (!result) return alert("Fein Fehler ist aufgetreten")

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return alert("test")

                return alert("Fein Fehler ist aufgetreten")
            }

            setStages(result)
            setStagesLoading(AppLoadingStates[200].default)
        }

        getStages()

    }, [])


    const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const query: any = document.getElementById("customerSearch")
        const res = await complexSearch(customers, attributes, query.value)
        if (!res) return setSearchData(customers)

        setUsedQuery(query.value)
        setSearchData(res)
    }

    const handleQuerySave = async () => {
        const filtername: any = document.getElementById("filterName")
        const filterdesc: any = document.getElementById("filterDescription")
        if (!filtername.value || !usedQuery) return alert("Keine gültige Eingabe")

        const result = await changeData("/filters", "POST", {
            "name": filtername.value,
            "query": usedQuery,
            "description": (filterdesc.value ?? null),
            "module": "customer"
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    // gets triggered when select dropdown is changed
    const handleFilterChange = async (selectedFilter: any) => {
        const res = await complexSearch(customers, attributes, selectedFilter.value)
        if (!res) return alert("Ungültige Eingabe")

        setSearchData(res)
    }

    // syncs customers with xentral instance
    const handleSync = async () => {
        openModal("syncModal")
        const result = await changeData("/customers/refresh", "POST", {})
        if (!result || isNumber(result)) return alert("En Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleSort = async () => {
        const sorted = await sortDesc(customers)
        const sortedCopy = [...sorted]
        setSearchData(sortedCopy);
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Kunden</Paragraph>
            <Container margin="3em 0 0 0">
                <Container>
                    <Widget>
                        <Container display="flex" align_items="center">
                            <Paragraph font_size="1.1em" margin="0 2em 0 0.7em">Suche:</Paragraph>
                            <form  onSubmit={handleSearch}>
                                <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="23vw" id="customerSearch" />
                            </form> 
                        </Container>
                    </Widget>
                </Container>
            </Container>
            <Container margin="3em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Container width="20%">
                    <Widget overflow="visible" display="flex" align_items="center">
                        <Paragraph font_size="1.1em" margin="0 2em 0 0.7em">Filter:</Paragraph>
                        {filterLoading ? (
                            <Container width="23vw" overflow="visible">
                                <Select options={filters} placeholder="Bitte wähle einen Filter" onChange={handleFilterChange} />
                            </Container>
                        ) : (
                            <Paragraph font_size="1.2em">{filterLoading}</Paragraph>
                        )}
                    </Widget>
                    <Widget margin="3em 0 0 0">
                        <Paragraph font_size="1.2em" font_weight="600" margin="0.2em 0 0 1em">Kunden</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1em 0 0.5em 0">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-lg btn-circle btn-info" >
                                    <Paragraph font_size="1.5em" text_align="center">{searchData.length}</Paragraph>
                                </Button>
                            ) : (
                                <Paragraph font_size="1.3em">{loading}</Paragraph>
                            )}
                        </Container>
                    </Widget>
                    <Widget margin="3em 0 0 0">
                        <Paragraph font_size="1.2em" font_weight="600">Synchronisieren</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1.5em 0 0 0">
                            <Button classes="btn btn-ghost" tooltip="Xentral-Synchronisation" onClick={() => handleSync()}>
                                <FaSync size={34} />
                            </Button>
                        </Container>
                    </Widget>
                    <Modal id="syncModal" headline="Synchronisation">
                        <Paragraph font_size="1.1em">Die Kunden werden nun synchronisiert. Dies kann kurz etwas dauern</Paragraph>
                        <Paragraph font_size="1.1em">Bitte warten, dieses Fenster schließt automatisch</Paragraph>
                        <Container display="flex" justify_content="center">
                            <span className="loading loading-spinner text-primary"></span>
                        </Container>
                    </Modal>
                </Container>
                <Container width="76%">
                    <Widget max_height="1100px" overflowy="auto">
                        {loading === AppLoadingStates[200].default && stagesLoading ===  AppLoadingStates[200].default ? (
                            <Table data={searchData} columns={customerColumns} baseUrl="/kunden/single" stages={stages} sortMethod={() => handleSort()} allowEllipsis={true}/>
                        ) : (
                            <Paragraph font_size="1em">{loading}</Paragraph>
                        )}
                    </Widget>
                    <Widget margin="2em 0 3em 0" display="flex" justify_content="space-between" padding="1.2em">
                        <Container>
                            <Button classes="btn btn-wide btn-md btn-info" onClick={() => openModal("saveFilterModal")}>Als Filter speichern</Button>
                        </Container>
                        <Modal id="saveFilterModal" headline="Filter speichern">
                            <Paragraph font_size="1.1em" margin="1em 0 1.7em 0">Legen Sie einen Namen für den ausgewählten Filter fest</Paragraph>
                            <FormContainer id="filterName" label="Filtername" />
                            <FormContainer id="filterDescription" label="Filterbeschreibung (Optional)" />
                            <Widget padding="2em" margin="1.3em 0 2.2em 0">
                                <Paragraph font_size="1.1em" font_weight="600">{usedQuery}</Paragraph>
                            </Widget>
                            <Button classes="btn btn-sm btn-primary" onClick={() => handleQuerySave()}>Speichern</Button>
                        </Modal>
                        <Container>
                            <Button classes="btn btn-md btn-accent" margin="0 2em 0 0" onClick={() => openModal("loadBrevo")}>Lade in Brevo</Button>
                            <BrevoModal customers={searchData} />
                            <Button classes="btn btn-md btn-accent">
                                <CSVLink data={searchData} filename={"customers.csv"} style={{
                                    display: "flex"
                                }}>
                                    <Paragraph font_size="1em">Export</Paragraph>
                                    <FaFileExport size={24} />
                                </CSVLink>
                            </Button>
                        </Container>
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
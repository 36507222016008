import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface props  {
    data: number[]
}

export const ArticelPieChart = ({data}: props) => {

    const dataset = {
        labels: ['Lagerartikel', 'Portoartikel', 'Rabattartikel', 'Artikel mit Serien.', 'Artikel Online', 'Matrixprodukte', 'Varianten Lagerartikel'],
        datasets: [
            {
                label: 'Artikel',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(90, 200, 0, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    '#6eaa5e'
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div style={{
            display:  "flex",
            justifyContent: "center"
        }}>
            <Pie data={dataset} height="305px" options={{ maintainAspectRatio: false }}/>
        </div>
    )
}
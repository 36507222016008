import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Widget } from "../../components/widget/Widget";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Input } from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { useNavigate } from "react-router-dom";
import { changeData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";

export const RegisterPage = () => {

    const [loading, setLoading] = React.useState<string>()
    const navigate = useNavigate()

    const handleRegister = async () => {
        // register
        const username: any = document.getElementById("usernameRegister")
        const password: any  = document.getElementById("passwordRegister")
        const password2: any  = document.getElementById("passwordRegister2")

        if(!password.value || !password2.value || !username.value) return alert("Keine gültigen Eingaben")
        if(password.value !== password2.value) return alert("Wiederholtes password ist falsch")

        const result = await changeData("/signin", "POST", {
            "username": username.value,
            "password": password.value,
            "verified": false
        })

        if(!result) return setLoading(AppLoadingStates[500])
        if(isNumber(result)) {
            const code: keyof typeof AppLoadingStates = result
            const loadingState = AppLoadingStates[code]

            // should be always true
            if(typeof loadingState === "string") return setLoading(loadingState)

            return setLoading(AppLoadingStates[500])
        }

        navigate("/")
    }


    return (
        <Page showSidebar={false}>
            <Container display="flex" justify_content="center" flex_direction="column" margin="5em 0 1em 0" width="100%">
                <Widget padding="2em">
                    <Paragraph font_size="2.3em" font_weight="600" text_align="center">Registrieren</Paragraph>
                    <Container margin="3em 0 0 0">
                        <Paragraph font_size="1.4em" margin="0 0 0.4em 0">Nutzername</Paragraph>
                        <Input placeholder="Nutzername..." padding="1em" height="55px" width="360px" borderRadius="10px" id="usernameRegister"/>
                    </Container>
                    <Container margin="1.6em 0 0 0">
                        <Paragraph font_size="1.4em" margin="0 0 0.4em 0">Passwort</Paragraph>
                        <Input placeholder="Passwort..." padding="1em" height="55px" width="360px" borderRadius="10px" id="passwordRegister"/>
                    </Container>
                    <Container margin="1.6em 0 0 0">
                        <Paragraph font_size="1.4em" margin="0 0 0.4em 0">Passwort wiederholen</Paragraph>
                        <Input placeholder="Passwort..." padding="1em" height="55px" width="360px" borderRadius="10px" id="passwordRegister2"/>
                    </Container>
                    <Container margin="1.6em 0 0 0">
                        <Button classes="btn btn-warning btn-md" onClick={handleRegister}>Anfrage senden</Button>
                    </Container>
                    {loading && (
                        <Container margin="1em 0 0 0" width="100%" word_break="break-word">
                            {loading === AppLoadingStates[200].register ? (
                                <>
                                    <Paragraph font_size="1.4em" color="green">{loading}</Paragraph>
                                </>
                            ) : (
                                <>
                                    <Paragraph font_size="1.4em" color="red">{loading}</Paragraph>
                                </>
                            )}
                        </Container>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
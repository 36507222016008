import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface props {
    strs: string[]
}

export const ShopsHorizontalbar = ({strs}: props) => {

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right' as const,
          },
          title: {
            display: true,
            text: 'Aktive Shopexporter & Aufträge pro Plattform',
          },
        },
    };

    const labels = strs.map((item) => {
        return item.split(")")[0] + ")"
    })

    const chartdata = {
        labels,
        datasets: [
          {
            label: 'Aufträge pro Shop',
            data: strs.map((item) => {
              console.log(item)
                return item.split(":")[2].split("/")[0]
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }
        ],
      };

    return <Bar options={options} data={chartdata} height="350px"/>
}
import { FaSearch } from "react-icons/fa";

interface props {
    borderRadius?: string
    border?: string
    padding?: string
    margin?: string
    height?: string
    width?: string
    fontSize?: string
    background?: string
    placeholder: string
    id?: string
    type?: string
    value?: string
    disable?: boolean
}

export const Input = ({ borderRadius, border, padding, margin, height, width, fontSize, background, placeholder, id, type, value, disable }: props) => {

    return (
        <input id={id} placeholder={placeholder} type={type} style={{
            borderRadius: borderRadius,
            border: border,
            padding: padding,
            margin: margin,
            height: height,
            width: width,
            fontSize: fontSize,
            background: background,
        }} defaultValue={value} />
    )
}
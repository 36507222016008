import { TableColumn } from "../components/table/Table"

export interface Placetelconfig {
    id?: number
    acceptXml: string
    declineXml: string
    targetnumber: string
    configType: string
    commentText?: string
}

// map to inferface, needed for table

export const placetelconfigColumns: TableColumn<Placetelconfig>[] = [
    { key: 'targetnumber', label: 'Zielnummer' },
    { key: 'configType', label: 'Kürzel' },
    { key: 'commentText', label: 'Notiz' },
];
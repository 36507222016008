import { TableColumn } from "../components/table/Table"

export interface Dashboard {
    id?: number
    customer: number
    dashboardname: string
    url: string
    created_by: string
}

// map to inferface, needed for table

export const dashboardColumns: TableColumn<Dashboard>[] = [
    { key: 'dashboardname', label: 'Dashboard' },
    { key: 'url', label: 'ID' },
    { key: 'created_by', label: 'Erstellt von' },
];
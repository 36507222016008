import React from "react"
import { Button } from "../button/Button"
import { openModal } from "../../helper/OpenModal"

interface props {
    text: string
    margin?: string
    // used to display button and open modal -> to add for example customer instance
    modalId?: string
}

export const Alert = ({text, margin, modalId}: props) => {
    
    return (
        <div role="alert" className="alert alert-warning" style={{
            margin: margin
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>Hinweis: {text}</span>
            {modalId && (
                <Button classes="btn btn-circle" onClick={() => openModal(modalId)}>+</Button>
            )}
        </div>
    )
}
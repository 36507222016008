import { useNavigate } from "react-router-dom"
import { Container } from "../../../components/container/Container"
import { Paragraph } from "../../../components/paragraph/Paragraph"

interface props {
    url: string
    text: string
    active: boolean
    first?: boolean
    last?: boolean
}

export const Navitem = ({text, url, active, first, last}: props) => {

    const navigate = useNavigate()

    return (
        <Container cursor="pointer" height="60px" width="11vw" display="flex" justify_content="center" align_items="center" onClick={() => navigate(url)} background={active ? "white" : "#e8e9eb"} border_top_left_radius={first ? "15px" : "0px"} border_top_right_radius={last ? "15px" : "0px"}>
            <Paragraph font_size="1.4em">{text}</Paragraph>
        </Container>
    )
}
import React from "react"
import { useParams } from "react-router-dom"
import { Page } from "../../../components/page/Page"
import { Paragraph } from "../../../components/paragraph/Paragraph"
import { Customer } from "../../../interfaces/Customer"
import { AppLoadingStates } from "../../../data/AppLoadingStates"
import { getData } from "../../../helper/Utils"
import { isNumber } from "../../../helper/ConvertToStrings"
import { Container } from "../../../components/container/Container"
import { Widget } from "../../../components/widget/Widget"
import { Attribute } from "../../../interfaces/Attribute"
import { CiCalendarDate, CiHome, CiMail, CiViewList } from "react-icons/ci"
import { CiShoppingBasket } from "react-icons/ci"
import { CiViewBoard } from "react-icons/ci";
import { MdNumbers } from "react-icons/md"
import { GoPackage } from "react-icons/go"
import { MdOutlineInventory } from "react-icons/md"
import { CiShoppingCart } from "react-icons/ci"
import { MdOutlineFactory } from "react-icons/md"
import { TbLetterV } from "react-icons/tb"
import { MdDataObject } from "react-icons/md"
import { CiMoneyBill } from "react-icons/ci"

export const CustomerSinglePage = () => {
    const [customer, setCustomer] = React.useState<Customer>()
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    const [attributes, setAttributes] = React.useState<Attribute[]>([])
    const [attributesLoading, setAttributesLoading] = React.useState<string>(AppLoadingStates[0])

    const { id } = useParams()

    const sidebarColor = "#5489de"
    const gray = "#838485"

    React.useEffect(() => {
        const getCustomer = async () => {
            const result = await getData("/customers/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCustomer(result.dataValues)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomer()
    }, [])

    React.useEffect(() => {
        const getAttributes = async () => {
            const result = await getData("/attributes/" + id)
            if (!result) return setAttributesLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setAttributesLoading(loadingState)

                return setAttributesLoading(AppLoadingStates[500])
            }

            setAttributes(result)
            setAttributesLoading(AppLoadingStates[200].default)
        }

        getAttributes()
    }, [])

    return (
        <Page showSidebar={true}>
            <Container display="flex" justify_content="center">
                {(loading === AppLoadingStates[200].default && attributesLoading === AppLoadingStates[200].default) ? (
                    <Widget display="flex" width="66vw" padding="0" margin="0 0 1.8em 0">
                        <Container width="30%">
                            <Container padding="1.3em 0 1.3em 1.3em" display="flex" justify_content="center">
                                <Container border_radius="50%" width="138px" height="138px" overflow="hidden" background="#fff" display="flex" justify_content="center">
                                    {attributes!.find((item: Attribute) => item.key === "Firmenlogo") && (
                                        <img src={attributes!.find((item: Attribute) => item.key === "Firmenlogo")?.value} style={{
                                            width: "100%"
                                        }} />
                                    )}

                                </Container>
                            </Container>
                            <Container background={sidebarColor} border_bottom_left_radius="15px" height="86%">
                                <Container padding="1.3em">
                                    <Paragraph font_size="1.4em" color="#fff" font_weight="600">Kontakt</Paragraph>
                                    <Container display="flex" margin="1.3em 0 0 0">
                                        <CiMail size={22} color="#fff" />
                                        <Paragraph font_size="1em" color="#fff" margin="0 0 0 1em">{customer?.email}</Paragraph>
                                    </Container>
                                </Container>
                                <Container padding="1.3em" margin="0.2em 0 0 0">
                                    <Paragraph font_size="1.4em" color="#fff" font_weight="600">Onboardingphase & Lead</Paragraph>
                                    <Container display="flex" margin="1em 0 0 0">
                                        <CiViewBoard size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Phase: {attributes!.find((item: Attribute) => item.key === "Onboarding Phase")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <CiHome size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Lead: {attributes!.find((item: Attribute) => item.key === "Lead-Quelle")?.value}</Paragraph>
                                    </Container>
                                </Container>
                                <Container padding="1.3em" margin="0.2em 0 0 0">
                                    <Paragraph font_size="1.4em" color="#fff" font_weight="600">Meine dXL-Produkte</Paragraph>
                                    <Container margin="1em 0 0 0">
                                        {attributes!.filter((item: Attribute) => item.key === "dXL-Produkt").map((item, index) => (
                                            <Container display="flex" margin="0.5em 0 0 0">
                                                <CiShoppingBasket size={22} color="#fff" />
                                                <Paragraph font_size="1em" key={index} color="#fff" margin="0 0 0 1em">{item.value}</Paragraph>
                                            </Container>
                                        ))}
                                    </Container>
                                </Container>
                                <Container padding="1.3em" margin="0.2em 0 0 0">
                                    <Paragraph font_size="1.4em" color="#fff" font_weight="600">Mein Artikelmanagement</Paragraph>
                                    <Container margin="1em 0 0 0" display="flex">
                                        <CiCalendarDate size={22} color="#fff" />
                                        <Paragraph font_size="1em" margin="0 0 0 1em" color="#fff">MHD: {attributes!.find((item: Attribute) => item.key === "MHD")?.value}</Paragraph>
                                    </Container>
                                    <Container margin="0.5em 0 0 0" display="flex">
                                        <GoPackage size={22} color="#fff" />
                                        <Paragraph font_size="1em" margin="0 0 0 1em" color="#fff">Charge: {attributes!.find((item: Attribute) => item.key === "Chargen")?.value}</Paragraph>
                                    </Container>
                                    <Container margin="0.5em 0 0 0" display="flex">
                                        <MdNumbers size={22} color="#fff" />
                                        <Paragraph font_size="1em" margin="0 0 0 1em" color="#fff">Seriennummern: {attributes!.find((item: Attribute) => item.key === "Chargen")?.value}</Paragraph>
                                    </Container>
                                </Container>
                                <Container padding="1.3em" margin="0.2em 0 0 0">
                                    <Paragraph font_size="1.4em" color="#fff" font_weight="600">Meine Beschaffungsprozesse:</Paragraph>
                                    <Container display="flex" margin="1em 0 0 0">
                                        <CiShoppingCart size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Einkauf: {attributes!.find((item: Attribute) => item.key === "Einkauf")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <MdOutlineFactory size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Produktion: {attributes!.find((item: Attribute) => item.key === "Produktion")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <CiViewList size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Produktionsstücklisten: {attributes!.find((item: Attribute) => item.key === "Produktionsstücklisten")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <TbLetterV size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Variantenartikel: {attributes!.find((item: Attribute) => item.key === "Variantenartikel")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <CiViewList size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">Stücklisten: {attributes!.find((item: Attribute) => item.key === "Stücklisten")?.value}</Paragraph>
                                    </Container>
                                    <Container display="flex" margin="0.5em 0 0 0">
                                        <CiViewList size={22} color="#fff" />
                                        <Paragraph margin="0 0 0 1em" color="#fff" font_size="1em">JiT-Stücklisten: {attributes!.find((item: Attribute) => item.key === "JiT-Stücklisten")?.value}</Paragraph>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container width="70%" background="#fff" border_top_right_radius="15px" border_bottom_right_radius="15px" padding="1.5em 1.3em 0 2em">
                            <Paragraph font_size="1.9em" font_weight="600" textoverflow="ellipsis" overflow="auto" whitespace="no-wrap" color={sidebarColor}>{customer!.name}</Paragraph>
                            <Paragraph font_size="1.2em" font_weight="600" margin="0.5em 0 0 0" textoverflow="ellipsis" overflow="auto" whitespace="no-wrap">Branche: {attributes!.find((item: Attribute) => item.key === "Branche")?.value ?? ""}</Paragraph>
                            <Container margin="2.1em 0 0 0">
                                <Container display="flex" justify_content="space-between">
                                    <Container display="flex">
                                        <Container display="flex" justify_content="center" width="55px" height="55px" border_radius="50%" background={sidebarColor}>
                                            <CiShoppingBasket color="#fff" size={22} />
                                        </Container>
                                        <Paragraph font_weight="600" font_size="1em" margin="0 0 0 1em">E-Commerce</Paragraph>
                                    </Container>
                                    <Container width="60%" margin="0.3em 0 0 0" border_top={`3px solid ${sidebarColor}`}><></></Container>
                                </Container>
                                <Container margin="1.2em 0 0 3em">
                                    <Container display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Shops:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Shop")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Marktplätze:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Marktplatz")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0">
                                        <Paragraph font_size="1em" color={gray}>Buisness Modell:</Paragraph>
                                        <Container display="flex" justify_content="center" flex_direction="column">
                                            <Container border_bottom="2px solid black" width="15vw" display="flex">
                                                <Container border_right="2px solid black" padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">B2B</Paragraph>
                                                </Container>
                                                <Container border_right="2px solid black" padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">B2C</Paragraph>
                                                </Container>
                                                <Container padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">D2C</Paragraph>
                                                </Container>
                                            </Container>
                                            <Container width="15vw" display="flex">
                                                <Container border_right="2px solid black" padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">{attributes!.find((item: Attribute) => item.key === "B2B")?.value}</Paragraph>
                                                </Container>
                                                <Container border_right="2px solid black" padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">{attributes!.find((item: Attribute) => item.key === "B2C")?.value}</Paragraph>
                                                </Container>
                                                <Container padding="0.6em" width="5vw">
                                                    <Paragraph font_size="1em" text_align="center">{attributes!.find((item: Attribute) => item.key === "D2C")?.value}</Paragraph>
                                                </Container>
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                            <Container margin="2em 0 0 0">
                                <Container display="flex" justify_content="space-between">
                                    <Container display="flex">
                                        <Container display="flex" justify_content="center" width="55px" height="55px" border_radius="50%" background={sidebarColor}>
                                            <MdOutlineInventory color="#fff" size={22} />
                                        </Container>
                                        <Paragraph font_weight="600" font_size="1em" margin="0 0 0 1em">Inventory & Returns</Paragraph>
                                    </Container>
                                    <Container width="60%" margin="0.3em 0 0 0" border_top={`3px solid ${sidebarColor}`}><></></Container>
                                </Container>
                                <Container margin="1.2em 0 0 3em">
                                    <Container display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Lager & Fulfiller:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === ("Lager & Fulfiller:" || "Lager / Fulfiller"))?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Retouren:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Retouren")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Versand Dienstleister:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Versanddienstleister")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                </Container>
                            </Container>
                            <Container margin="2em 0 0 0">
                                <Container display="flex" justify_content="space-between">
                                    <Container display="flex">
                                        <Container display="flex" justify_content="center" width="55px" height="55px" border_radius="50%" background={sidebarColor}>
                                            <MdDataObject color="#fff" size={22} />
                                        </Container>
                                        <Paragraph font_weight="600" font_size="1em" margin="0 0 0 1em">Daten & Analyse</Paragraph>
                                    </Container>
                                    <Container width="60%" margin="0.3em 0 0 0" border_top={`3px solid ${sidebarColor}`}><></></Container>
                                </Container>
                                <Container margin="1.2em 0 0 3em">
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Schnittstellen:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Schnittstelle")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">BI:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "BI")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                </Container>
                            </Container>
                            <Container margin="2em 0 0 0">
                                <Container display="flex" justify_content="space-between">
                                    <Container display="flex">
                                        <Container display="flex" justify_content="center" width="55px" height="55px" border_radius="50%" background={sidebarColor}>
                                            <MdOutlineInventory color="#fff" size={22} />
                                        </Container>
                                        <Paragraph font_weight="600" font_size="1em" margin="0 0 0 1em">Software</Paragraph>
                                    </Container>
                                    <Container width="60%" margin="0.3em 0 0 0" border_top={`3px solid ${sidebarColor}`}><></></Container>
                                </Container>
                                <Container margin="1.2em 0 0 3em">
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Tools:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Tools")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Accounttyp-Systeme:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Accounttyp-System")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Feste IP-Adresse:</Paragraph>
                                        <Paragraph font_size="1em" margin="0 0 0 0.7em">{attributes!.find((item: Attribute) => item.key === "Feste IP")?.value}</Paragraph>
                                    </Container>
                                </Container>
                            </Container>
                            <Container margin="2em 0 0 0">
                                <Container display="flex" justify_content="space-between">
                                    <Container display="flex">
                                        <Container display="flex" justify_content="center" width="55px" height="55px" border_radius="50%" background={sidebarColor}>
                                            <CiMoneyBill color="#fff" size={22} />
                                        </Container>
                                        <Paragraph font_weight="600" font_size="1em" margin="0 0 0 1em">Finanzen & Zahlungsprozesse</Paragraph>
                                    </Container>
                                    <Container width="60%" margin="0.3em 0 0 0" border_top={`3px solid ${sidebarColor}`}><></></Container>
                                </Container>
                                <Container margin="1.2em 0 0 3em">
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Bankanbindung:</Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "Bankanbindung")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">POS: </Paragraph>
                                        {attributes!.filter((item: Attribute) => item.key === "POS")?.map((item, index) => (
                                            <Paragraph font_size="1em" key={index} margin="0 0 0 0.8em">{item.value},</Paragraph>
                                        ))}
                                    </Container>
                                    <Container margin="0.8em 0 0 0" display="flex">
                                        <Paragraph font_size="1em" color={gray} width="35%">Buchhaltung</Paragraph>
                                        <Paragraph font_size="1em" margin="0 0 0 0.7em">{attributes!.find((item: Attribute) => item.key === "Buchhaltung")?.value}</Paragraph>
                                    </Container>
                                </Container>
                            </Container>
                            <Container margin="2.8em 2.4em 1.4em 2.4em">
                                <Widget>
                                    <Paragraph font_size="1em" color={gray}>Kommentar:</Paragraph>
                                    <Paragraph font_size="1em" margin="1em 0 0 0">{attributes!.find((item: Attribute) => item.key === "Kommentar")?.value}</Paragraph>                              
                                </Widget>
                            </Container>
                        </Container>
                    </Widget>
                ) : (
                    <Container>
                        <Paragraph text_align="center" font_size="1em">{loading} + {attributesLoading}</Paragraph>
                    </Container>
                )}
            </Container>
        </Page>
    )
}
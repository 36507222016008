import React from "react"
import { Page } from "../../components/page/Page"
import { changeData, getData } from "../../helper/Utils"
import { AppLoadingStates } from "../../data/AppLoadingStates"
import { isNumber } from "../../helper/ConvertToStrings"
import { Paragraph } from "../../components/paragraph/Paragraph"
import { Container } from "../../components/container/Container"
import { Widget } from "../../components/widget/Widget"
import { Button } from "../../components/button/Button"
import { FaSync } from "react-icons/fa"
import { Modal } from "../../components/modal/Modal"
import { openModal } from "../../helper/OpenModal"

export const LiveAgentPage = () => {
    const [numberContacts, setNumberContacts] = React.useState<number>(0)
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getNumberContacts = async () => {
            const result = await getData("/liveagent/number")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setNumberContacts(result)
            setLoading(AppLoadingStates[200].default)
        }

        getNumberContacts()
    }, [])

    const handleSync = async () => {
        openModal("syncWait")
        const res = await changeData("/liveagent/sync", "POST", {})
        if(!res || isNumber(res)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Liveagent</Paragraph>
            <Container margin="3em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Container width="27%">
                    <Widget>
                        <Paragraph font_size="1.2em" font_weight="600" margin="0.2em 0 0 1em">Kontakte in Liveagent</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1em 0 0.5em 0">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-lg btn-circle btn-info" >
                                    <Paragraph font_size="2em" text_align="center">{numberContacts}</Paragraph>
                                </Button>
                            ) : (
                                <Paragraph font_size="1.3em">{loading}</Paragraph>
                            )}
                        </Container>
                    </Widget>
                    <Widget margin="3em 0 0 0">
                        <Paragraph font_size="1.2em" font_weight="600">Synchronisieren</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1.5em 0 0 0">
                            <Button classes="btn btn-ghost" tooltip="Xentral->Liveagent sync" onClick={() => handleSync()}>
                                <FaSync size={34} />
                            </Button>
                        </Container>
                        <Modal headline="Synchronisation" id="syncWait">
                            <Paragraph font_size="1em">Die Synchronisation läuft, bitte warte einen Moment...</Paragraph>
                            <Paragraph font_size="1em">Dieses Popup-Fenster schließt automatisch bei einer erfolgreichen Synchronisation</Paragraph>
                        </Modal>
                    </Widget>
                </Container>
                <Widget width="69%">

                </Widget>
            </Container>
        </Page>
    )
}
import { TableColumn } from "../components/table/Table"

export interface Filter {
    id?: number
    name: string
    query: string
    module: string
    description?: string
    createdBy?: number
}

// map to inferface, needed for table

export const filterColumns: TableColumn<Filter>[] = [
    { key: 'name', label: 'Filtername' },
    { key: 'module', label: 'Modul' },
    { key: 'description', label: 'Beschreibung' },
    { key: 'createdBy', label: 'Erstellt von' },
];

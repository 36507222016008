import React from "react";
import { Filter, filterColumns } from "../../interfaces/Filter";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Navitem } from "./components/Navitem";
import { Input } from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import { Widget } from "../../components/widget/Widget";
import { openModal } from "../../helper/OpenModal";
import { Modal } from "../../components/modal/Modal";
import { Table } from "../../components/table/Table";
import { Select } from "../../components/select/Select";

export const SettingsFilterPage = () => {

    const [filters, setFilters] = React.useState<Filter[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getFilters = async () => {
            const result = await getData("/filters")
            if(!result) return setLoading(AppLoadingStates[500])

            if(isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if(typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setFilters(result)
            setLoading(AppLoadingStates[200].default)
        }

        getFilters()

    }, [])

    const handleSearch = async () => {

    }

    const handleNewFilter = async () => {
        const filtername: any = document.getElementById("filterNameModal")
        const filterquery: any  = document.getElementById("filterQueryModal")
        const filterqmodule: any  = document.getElementById("filterModuleModal")
        const filtercomment: any = document.getElementById("filterCommentModal")

        if(!filtername.value || !filterquery.value || !filterqmodule.value) return alert("Ungültige Eingabe")

        const result = await changeData("/filters",  "POST", {
            "name": filtername.value,
            "query": filterquery.value,
            "description": (filtercomment.value ?? ""),
            "module": filterqmodule.value
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }


    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Einstellungen</Paragraph>
            <Container margin="2.5em 0 0 0">
                <Container display="flex">
                    <Navitem url="/settings/pair"  text="Attribut Paare" active={false} first={true}/>
                    <Navitem url="/settings/filter"  text="Filter" active={true}/>
                    <Navitem url="/settings/stage"  text="Stages" active={false} />
                    <Navitem url="/settings/formulare" text="Formulare" active={false} last={true} />
                </Container>
                <Container background="#fff" padding="2em" border_bottom_left_radius="15px" border_bottom_right_radius="5px">
                    <Widget display="flex" align_items="center" justify_content="space-between">
                        <Container>
                            <Input placeholder="Suche nach filter..." id="filterSearch" height="40px" borderRadius="10px" padding="0.7em" width="35vw"/>
                            <Button classes="btn btn-warning btn-md" margin="0 0 0 2.5em" onClick={() => handleSearch()}>Anwenden</Button>
                        </Container>
                        <Button classes="btn btn-square btn-warning" tooltip="Erstellen" onClick={() => openModal("addFilterModal")}>
                            +
                        </Button>
                    </Widget>
                    <Modal id="addFilterModal" headline="Filter erstellen">
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Filtername</Paragraph>
                        <Input id="filterNameModal" placeholder="Name..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%"/>
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Filterquery</Paragraph>
                        <Input id="filterQueryModal" placeholder="Query..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%"/>
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Filterquery</Paragraph>
                        <Select id="filterModuleModal" padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%">
                            <option value="customer">Kundenfilter</option>
                            <option value="report">Berichtfilter</option>
                        </Select>
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Kommentar (optional)</Paragraph>
                        <Input id="filterCommentModal" placeholder="Kommentar..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%"/>
                        <Button classes="btn btn-warning" onClick={() => handleNewFilter()} margin="1em 0 0 0">Speichern</Button>
                    </Modal>
                    <Widget margin="2em 0 0 0">
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={filters} columns={filterColumns} deleteUrl="/filters"/>
                        ) : (
                            <Paragraph font_size="1.2em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
import React from "react";

interface props {
    id: string
    children: React.ReactNode
    headline: string
    closeButtonText?: string
    onClick?: () => void
}

export const Modal = ({id, children, headline, closeButtonText, onClick}: props) => {

    return (
        <dialog id={id} className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
                <h3 className="font-bold text-lg">{headline}</h3>
                {children}
                <div className="modal-action">
                    <form method="dialog">
                        <button className={`btn`} onClick={onClick}>{closeButtonText ?? "Schließen"}</button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}
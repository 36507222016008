import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { useNavigate, useParams } from "react-router-dom";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Form } from "../../interfaces/Form";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Widget } from "../../components/widget/Widget";
import { Button } from "../../components/button/Button";
import { MdDelete } from "react-icons/md";
import { Attributepair } from "../../interfaces/Attributepair";
import { Modal } from "../../components/modal/Modal";
import { closeModal, openModal } from "../../helper/OpenModal";
import { Input } from "../../components/input/Input";
import { CgAsterisk } from "react-icons/cg";
import Select from "react-select";
import { FormField } from "../../interfaces/Formfield";

export const SingleFormPage = () => {
    const [form, setForm] = React.useState<Form>()
    const [pairs, setPairs] = React.useState<Attributepair[]>([])
    const [fields, setFields] = React.useState<FormField[]>([])

    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [pairsLoading, setPairsLoading] = React.useState<string>(AppLoadingStates[0])
    const [fieldsLoading, setFieldsLoading] = React.useState<string>(AppLoadingStates[0])

    const [selectedKey, setSelectedKey] = React.useState<any>()

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getForm = async () => {
            const result = await getData("/forms/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setForm(result)
            setLoading(AppLoadingStates[200].default)
        }

        getForm()
    }, [])

    React.useEffect(() => {
        const getPairs = async () => {
            const result = await getData("/attributepairs")
            if (!result) return setPairsLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setPairsLoading(loadingState)

                return setPairsLoading(AppLoadingStates[500])
            }

            setPairs(result)
            setPairsLoading(AppLoadingStates[200].default)
        }

        getPairs()
    }, [])

    React.useEffect(() => {
        const getFields = async () => {
            const result = await getData("/formfields/" + id)
            if (!result) return setFieldsLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setFieldsLoading(loadingState)

                return setFieldsLoading(AppLoadingStates[500])
            }

            setFields(result)
            setFieldsLoading(AppLoadingStates[200].default)
        }

        getFields()
    }, [])


    const handleFormDelete = async () => {
        const result = await changeData("/forms/" + id, "DELETE", {})
        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        navigate("/settings/formulare")
    }

    
    const handleFieldDelete = async (field: FormField) => {
        // difference between unsaved field an saved field -> form_id
        if(field.form_id) {
            const result = await changeData("/formfields/fields/" + field.id, "DELETE", {})
            if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        }

        // every key is unique per form
        setFields(prev => {
            const found = prev.findIndex((item) => item.key === field.key)
            return prev.slice(0, found)
        })
    }

    // save modified field after click 
    const handleModifiedField = (index: number) => {
        const width: any = document.getElementById("fieldWidth" + index)
        const required: any = document.getElementById("requiredField" + index)


        if (!selectedKey) return alert("Ungültige Eingabe")

        setFields(prev => {
            return prev.map((item, i) => {
                if (i === index) {
                    return {
                        width: (width.value ?? "100%"),
                        required: required.checked,
                        key: selectedKey.value
                    }
                }

                return item
            })
        })

        closeModal(`fieldModal${index}`)
    }

    const getUnusedKeys = () => {
        const usedKeys = fields.map((field) => field.key)

        return Array.from(new Set(pairs
            .filter(item => !usedKeys.includes(item.key))
            .map(item => item.key)
        ))
    }

    const handleCreate = async () => {
        if (fields.length === 0) return alert("Ungütlige Eingabe")

        const result = await changeData("/formfields/" + id, "POST", {
            fields: fields
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        navigate("/settings/formulare")
    }

    return (
        <Page showSidebar={true}>
            {loading === AppLoadingStates[200].default ? (
                <Paragraph font_size="2.3em" font_weight="600">{form?.name} Formular</Paragraph>
            ) : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}
            <Container margin="2.5em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Widget width="15%">

                </Widget>
                <Widget display="flex" justify_content="space-between" align_items="center" width="77%">
                    <Paragraph font_size="1.3em" font_weight="600">Aktionen</Paragraph>
                    <Container>
                        <Button classes="btn btn-accent btn-circle" tooltip="Löschen">
                            <MdDelete size={24} onClick={() => handleFormDelete()} />
                        </Button>
                    </Container>
                </Widget>
            </Container>
            <Container margin="2em 0 0 0">
                <Widget>
                    <Container display="flex" justify_content="space-between">
                        <Paragraph font_size="1.2em" font_weight="500">Formular - Builder</Paragraph>
                        <Button classes="btn btn-primary" tooltip="Eingabefeld hinzufügen" onClick={() => setFields([...fields, {
                            width: "100"
                        }])}>+</Button>
                    </Container>
                    {fieldsLoading === AppLoadingStates[200].default ? (
                        <Container margin="1.5em 0 0 0" display="flex" flexWrap="wrap" gap="1.3em" justify_content="space-between">
                            {fields.map((item, index) => (
                                <>
                                    <Container key={index} border_radius="10px" background="#fff" width={item.width + "%"} display="flex" justify_content="space-between">
                                        <Container width="75%" cursor="pointer"padding="0.6em" onClick={() => openModal(`fieldModal${index}`)}>
                                            <Paragraph font_size="1em" textoverflow="ellipsis" overflow="auto" whitespace="no-wrap">{item.key ? item.key : "Klicke, um Konfiguration zu beenden"}</Paragraph>
                                        </Container>
                                        <Container display="flex" margin="0 0.5em 0 0">
                                        {item.required && (
                                            <CgAsterisk color="red" size={20} />
                                        )}
                                        <MdDelete size={24} cursor="pointer" onClick={() => handleFieldDelete(item)}/>
                                        </Container>
                                    </Container>
                                    <Modal id={`fieldModal${index}`} headline="Eingabefeld bearbeiten">
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Breite (als ganze Zahl)</Paragraph>
                                        <Input id={`fieldWidth${index}`} placeholder="Breite..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Key</Paragraph>
                                        {pairsLoading === AppLoadingStates[200].default ? (
                                            <Select options={getUnusedKeys().map((key) => ({
                                                label: key,
                                                value: key
                                            }))} onChange={(choice) => setSelectedKey(choice)} />
                                        ) : (
                                            <Paragraph font_size="1em">{pairsLoading}</Paragraph>
                                        )}

                                        <div className="form-control mt-3">
                                            <label className="label cursor-pointer">
                                                <Paragraph font_size="1em">Pflichtfeld</Paragraph>
                                                <input type="checkbox" className="checkbox" id={`requiredField${index}`} />
                                            </label>
                                        </div>
                                        <Button margin="1.5em 0 0 0" classes="btn btn-primary" onClick={() => handleModifiedField(index)}>Bestätigen</Button>
                                    </Modal>
                                </>
                            ))}
                        </Container>
                    ) : (
                        <Paragraph font_size="1em" margin="1em 0 0 0">{loading}</Paragraph>
                    )}
                    <Button classes="btn btn-accent" margin="2.6em 0 0 0" onClick={() => handleCreate()}>Erstellen</Button>
                </Widget>
            </Container>
        </Page>
    )
}
import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Loading } from "../../components/loading/Loading";
import { useNavigate } from "react-router-dom";

export const LogoutPage = () => {

    const navigate = useNavigate()

    React.useEffect(() => {
        // logout

        const interval = setInterval(() => {
            navigate("/products")
        }, 3000)

        return () => clearInterval(interval);

    }, [])

    return (
        <Page showSidebar={false}>
            <Container width="100%" display="flex" justify_content="center" margin="7em 0 0 0" flex_direction="column">
                <Paragraph font_size="2em" color="orange" margin="0 0 2em 0">Sie werden ausgeloggt - Bitte warten sie einen kleinen Moment</Paragraph>
                <Loading size="md"/>
            </Container>
        </Page>
    )
}
import React from "react"
import { Container } from "../container/Container"

interface props {
    tooltip?: string
    classes: string // daisyui classes to style
    children: React.ReactNode
    padding?: string
    background?: string
    color?: string
    font_size?: string
    font_weight?: string
    margin?: string
    onClick?: () => void
}

export const Button = ({children, classes, tooltip, padding, background, onClick, font_size, font_weight, color, margin}: props) => {

    return (
        <>
            {tooltip ? (
                <div className="tooltip tooltip-left tooltip-ghost" data-tip={tooltip}>
                    <button className={classes} style={{
                        padding: padding,
                        backgroundColor: background,
                        color: color,
                        fontSize: font_size,
                        fontWeight: font_weight,
                        margin: margin
                    }} onClick={onClick}>
                        {children}
                    </button>
                </div>
            ) : (
                <button className={classes} style={{
                    padding: padding,
                    backgroundColor: background,
                    color: color,
                    fontSize: font_size,
                    fontWeight: font_weight,
                    margin: margin
                }} onClick={onClick}>
                    {children}
                </button>
            )}
        </>
    )
}
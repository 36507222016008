import { useState, useEffect } from 'react';
type StorageKey = string;

type StorageValue = string | null;

const isLocalStorageAvailable = (): boolean => {
    try {
        const testKey = '__storage_test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

const useLocalStorage = <T>(
    key: StorageKey,
    initialValue: T
): [T, (value: T) => void] => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (!isLocalStorageAvailable()) {
            console.error('Local storage is not available.');
            return initialValue;
        }

        const item = localStorage.getItem(key);

        return item ? JSON.parse(item) : initialValue;
    });

    const setValue = (value: T): void => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            localStorage.setItem(key, JSON.stringify(valueToStore));
            setStoredValue(valueToStore);
        } catch (error) {
            console.error('Error setting local storage value:', error);
        }
    };

    useEffect(() => {
        setValue(storedValue);
    }, [storedValue, key]);

    return [storedValue, setValue];
};

export default useLocalStorage;

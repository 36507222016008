import { MdDelete } from "react-icons/md"
import { Container } from "../../../../components/container/Container"
import { Paragraph } from "../../../../components/paragraph/Paragraph"
import styles from "./Attribute.module.scss"
import { Attribute } from "../../../../interfaces/Attribute"
import { FaCheck } from "react-icons/fa"
import { ImCross } from "react-icons/im";

interface props {
    data: Attribute
}

export const ModalAttribute = ({data}: props) => {


    return (
        <div className={styles.attribute}>
            <Container display="flex" justify_content="space-between" padding="0 1em 0 1em" border_bottom="1px solid gray" height="44px">
                <Container display="flex" width="95%">
                    <Container width="40%">
                        <Paragraph font_size="1.1em" width="6vw" overflow="hidden" whitespace="no-wrap" textoverflow="ellipsis">
                            {data.key}
                        </Paragraph>
                    </Container>
                    <Container max_height="4vh" overflowy="scroll" width="50%">
                        <Paragraph font_size="1.1em">
                            {data.value}
                        </Paragraph>
                    </Container>
                    <Container max_height="4vh" overflowy="scroll" width="10%">
                        {data.public ? (
                            <FaCheck size={20} color="green" />
                        ) : (
                            <ImCross size={20} color="red" />
                        )}
                    </Container>
                </Container>
            </Container>
        </div>
    )
}
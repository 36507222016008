import { TableColumn } from "../components/table/Table";

export interface User {
    username?: string
    profile_id?: string
    name?: string
    verified: boolean
    createdAt?: string
}

export const userColumns: TableColumn<User>[] = [
    { key: 'username', label: 'Nutzername' },
    { key: 'name', label: 'Name' },
    { key: 'verified', label: 'Verifiziert' },
    { key: 'createdAt', label: 'Erstellt am' },
];
import React from "react";
import { Page } from "../../../components/page/Page";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { Customer } from "../../../interfaces/Customer";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../../../components/container/Container";
import { Paragraph } from "../../../components/paragraph/Paragraph";
import { Alert } from "../../../components/alert/Alert";
import { isNumber } from "../../../helper/ConvertToStrings";
import { changeData, getData } from "../../../helper/Utils";
import { Widget } from "../../../components/widget/Widget";
import { ArticelPieChart } from "../components/ArticelPieChart";
import { Systemhealth } from "../../../interfaces/Systemhealth";
import { ShopsHorizontalbar } from "../components/ShopsHorizontalBar";
import { FaEdit, FaSync } from "react-icons/fa";
import { Modal } from "../../../components/modal/Modal";
import { openModal } from "../../../helper/OpenModal";
import { Button } from "../../../components/button/Button";
import { Input } from "../../../components/input/Input";
import { MdDelete } from "react-icons/md";

export const SystemHealthPage = () => {
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [customer, setCustomer] = React.useState<Customer>()

    const [reportData, setReportData] = React.useState<Systemhealth[]>([])
    const [reportLoading, setReportLoading] = React.useState<string>(AppLoadingStates[0])

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getCustomer = async () => {
            const result = await getData("/customers/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCustomer(result)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomer()
    }, [])

    React.useEffect(() => {
        const getReport = async () => {
            const result = await getData("/customers/system/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setReportData(result)
            setReportLoading(AppLoadingStates[200].default)
        }

        getReport()
    }, [])

    // used for accessing faster certain datasets from reportdata
    const getWertFürKennzahl = (kennzahl: string) => {
        var found = reportData.find(function (dataset) {
            return dataset.Kennzahl === kennzahl;
        });
        return found ? found.Wert : "null";
    }

    const handleEdit = async () => {
        const instance: any = document.getElementById("editInstance")
        const report: any = document.getElementById("editReportId")
        const user: any = document.getElementById("editApiUsername")
        const pwd: any = document.getElementById("editApiPassword")

        if (!(instance.value || report.value || user.value || pwd.value)) return alert("Ungültige Eingabe")

        const result = await changeData("/customers/" + id, "PUT", {
            "instance": instance.value,
            "healthReportId": report.value,
            "dusername": user.value,
            "dpassword": pwd.value
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleSync = async () => {
        const result = await changeData("/attributes/" + id, "POST", [
            {
                key: "Shop",
                value: ""
            }
        ])
    }

    const handleDelete = async () => {
        const result = await changeData("/customers/" + id, "DELETE", {})
        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        navigate("/kunden/single/" + id)
    }

    return (
        <Page showSidebar={true}>
            {loading === AppLoadingStates[200].default ? (
                <Container display="flex" justify_content="space-between">
                    <Container display="flex">
                        <Paragraph font_size="2.3em" font_weight="600" margin="0.5em 0.8em 0.3em 0">Statusanalyse {customer?.name}</Paragraph>
                        <Button classes="btn btn-ghost btn-circle" margin="0.4em 0 0 0" tooltip="Kunden login Daten löschen" onClick={() => handleDelete()}>
                            <MdDelete size={26}/>
                        </Button>
                    </Container>
                    <Button classes="btn btn-primary btn-circle" tooltip="Attribute für Kunde automatisch anlegen">
                        <FaSync size={22} />
                    </Button>
                </Container>
            ) : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}

            {loading === AppLoadingStates[200].default ? (
                <Container>
                            
                            {reportLoading === AppLoadingStates[200].default ? (
                                <>
                                    <Container margin="2.3em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                                        <Container width="61%">
                                            <Widget display="flex" justify_content="space-between">
                                                <Container padding="1em" display="flex" justify_content="center" align_items="center" classes="bg-primary" border_radius="7px" flex_direction="column" width="7vw">
                                                    <Paragraph font_size="1em" width="100%" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis" text_align="center">Gutschriften</Paragraph>
                                                    <Paragraph font_size="1.7em" font_weight="600" margin="0.3em 0 0 0">{getWertFürKennzahl("07c Anzahl an Retouren / Gutschriften").split("/")[1]}</Paragraph>
                                                </Container>
                                                <Container padding="1em" display="flex" justify_content="center" align_items="center" classes="bg-secondary" border_radius="7px" flex_direction="column" width="7vw">
                                                    <Paragraph font_size="1em" width="100%" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis" text_align="center">Verbindlichkeiten</Paragraph>
                                                    <Paragraph font_size="1.7em" font_weight="600" margin="0.5em 0 0 0">{getWertFürKennzahl("07a Anzahl an Bestellungen / Verbindlichkeiten").split("/")[1]}</Paragraph>
                                                </Container>
                                                <Container padding="1em" display="flex" justify_content="center" align_items="center" classes="bg-success" border_radius="7px" flex_direction="column" width="7vw">
                                                    <Paragraph font_size="1em" width="100%" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis" text_align="center">Bestellungen</Paragraph>
                                                    <Paragraph font_size="1.7em" font_weight="600" margin="0.5em 0 0 0">{getWertFürKennzahl("07a Anzahl an Bestellungen / Verbindlichkeiten").split("/")[0]}</Paragraph>
                                                </Container>
                                                <Container padding="1em" display="flex" justify_content="center" align_items="center" classes="bg-warning" border_radius="7px" flex_direction="column" width="7vw">
                                                    <Paragraph font_size="1em" width="100%" text_align="center" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis">Aufträge abgeschlossen</Paragraph>
                                                    <Paragraph font_size="1.7em" font_weight="600" margin="0.5em 0 0 0">{getWertFürKennzahl("07b Anzahl an abgeschlossenen Aufträgen / Rechnungen").split("/")[0]}</Paragraph>
                                                </Container>
                                                <Container padding="1em" display="flex" justify_content="center" align_items="center" classes="bg-error" border_radius="7px" flex_direction="column" width="7vw">
                                                    <Paragraph font_size="1em" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis" width="100%" text_align="center">Rechnungen</Paragraph>
                                                    <Paragraph font_size="1.7em" font_weight="600" margin="0.5em 0 0 0">{getWertFürKennzahl("07b Anzahl an abgeschlossenen Aufträgen / Rechnungen").split("/")[1]}</Paragraph>
                                                </Container>
                                            </Widget>
                                            <Widget margin="2.7em 0 0 0">
                                                <Paragraph font_size="1.2em" font_weight="600" textdecoration="underline">Systemhealth Meldungen</Paragraph>
                                                <Container max_height="180px" overflow="auto" margin="1em 0 0 0" padding="1em" border_radius="10px" background="#fff">
                                                    {reportData.filter((item) => item.Kennzahl === "15 System Health").map((item, index) => (
                                                        <Container key={index} display="flex" align_items="center" border_bottom="1px solid gray" height="56px">
                                                            {item.Wert.replaceAll(/<[^>]+>/g, "")}
                                                        </Container>
                                                    ))}
                                                </Container>
                                            </Widget>
                                        </Container>
                                        <Widget padding="1em" width="34%">
                                            <Paragraph font_size="1.2em" font_weight="600" margin="0 0 0.8em 0" textdecoration="underline">Artikel</Paragraph>
                                            <Container margin="0 0 2em 0">
                                                <ArticelPieChart data={[
                                                    Number(getWertFürKennzahl("11a Anzahl Lagerartikel")),
                                                    Number(getWertFürKennzahl("11b Anzahl Portoartikel")),
                                                    Number(getWertFürKennzahl("11c Anzahl Rabattartikel")),
                                                    Number(getWertFürKennzahl("11g Anzahl Artikel mit Seriennummer")),
                                                    Number(getWertFürKennzahl("11h Anzahl Artikel mit Onlineshopverknüpfung")),
                                                    Number(getWertFürKennzahl("11d Anzahl Matrixprodukte / Varianten-Lager-Artikel").split("/")[0]),
                                                    Number(getWertFürKennzahl("11d Anzahl Matrixprodukte / Varianten-Lager-Artikel").split("/")[1])
                                                ]} />
                                            </Container>
                                            <Paragraph font_size="1em">Gesamtanzahl Artikel: {getWertFürKennzahl("11 Anzahl Artikel gesamt")}</Paragraph>
                                        </Widget>
                                    </Container>
                                    <Container display="flex" justify_content="space-between" align_items="flex-start" margin="2.3em 0 0 0">
                                        <Widget width="31%">
                                            <Paragraph font_size="1.2em" font_weight="600" textdecoration="underline">Lagerinformationen</Paragraph>
                                            <Container overflow="auto" max_height="350px" padding="0.6em">
                                                <Container margin="1em 0 0 0">
                                                    <Container display="flex" justify_content="space-between" border_bottom="1px solid gray" padding="0 0 0.4em 0">
                                                        <Paragraph font_size="0.8em" font_weight="600" width="36%">Lager</Paragraph>
                                                        <Paragraph font_size="0.8em" width="31%" text_align="center">Lagerplätze / Sperrlager</Paragraph>
                                                        <Paragraph font_size="0.8em" width="33%" text_align="center">Artikel mit Bestand inkl. Sperrlager / Gesamtbestad</Paragraph>
                                                    </Container>
                                                    <Container margin="1em 0 0 0">
                                                        {getWertFürKennzahl("13 Lager;  Anzahl Lagerplätze / Anzahl Sperrlagerplätze | Anzahl Artikel mit Bestand inkl. Sperrlager / Gesamtbestand").split(",").map((item, index) => (
                                                            <Container display="flex" justify_content="space-between" align_items="center" height="52px">
                                                                <Paragraph font_size="0.8em" width="36%" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis">{item.split(":")[0]}</Paragraph>
                                                                <Paragraph font_size="0.8em" width="31%" text_align="center">{item.split("|")[0].split(":")[1]}</Paragraph>
                                                                <Paragraph font_size="0.8em" width="33%" text_align="center">{item.split("|")[1]}</Paragraph>
                                                            </Container>
                                                        ))}
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </Widget>
                                        <Widget width="64%">
                                            <Paragraph font_size="1.2em" font_weight="600" textdecoration="underline">Shopexporter</Paragraph>
                                            <Container>
                                                <ShopsHorizontalbar strs={getWertFürKennzahl("06 Aktive Shopexporter (Projekt): Anzahl Aufträge / Umsatzvolumen").split(",")} />
                                            </Container>
                                        </Widget>
                                    </Container>
                                </>
                            ) : (
                                <Paragraph font_size="1.2em">{reportLoading}</Paragraph>
                            )}
                    
                </Container>
            ) : (
                <Paragraph font_size="1.2em" font_weight="600">{loading}</Paragraph>
            )
            }
        </Page >
    )
}


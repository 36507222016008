import React from "react"
import styles from "./Table.module.scss"
import { useNavigate } from "react-router-dom";
import { openModal } from "../../helper/OpenModal";
import { Modal } from "../modal/Modal";
import { Button } from "../button/Button";
import { changeData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Container } from "../container/Container";
import { Paragraph } from "../paragraph/Paragraph";
import { Stage, StageWithCustomer } from "../../interfaces/Stage";
import { FaSortAlphaDown } from "react-icons/fa";

export interface TableColumn<T> {
  key: keyof T;
  label: string;
}

// baseurl = link to single page
interface TableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  baseUrl?: string
  deleteUrl?: string
  stages?: StageWithCustomer[]
  sortMethod?: () => void
  allowEllipsis?: boolean
}

export const Table = <T,>({ data, columns, baseUrl, deleteUrl, stages, sortMethod, allowEllipsis }: TableProps<T>) => {

  const [deleteId, setDeleteId] = React.useState<number>()

  const navigate = useNavigate()

  const handleClick = (item: any) => {
    if (!item.id) return

    if (deleteUrl) {
      setDeleteId(item.id)
      return openModal("deleteModal")
    }

    if (!baseUrl) return

    navigate(baseUrl + "/" + item.id)
  }

  const handleDelete = async () => {
    const result = await changeData(deleteUrl + "/" + deleteId, "DELETE", {})
    if (!result || isNumber(result)) return alert("Es ist ein Fehler aufgetreten")

    window.location.reload()
  }

  const handleDeleteStage = async (id: number, name: string) => {
    const result = await changeData("/customers/stage/" + id, "DELETE", {
      stagename: name
    })
    if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

    window.location.reload()
  }

  

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        {columns.map((column, index) => (
          <a className={styles.header__item} key={index}>
            {column.label === "Name" ? (
              <Container display="flex" justify_content="center">
                <Container margin="0 0.8em 0 0">{column.label}</Container>
                <FaSortAlphaDown size={20} style={{
                  cursor: "pointer"
                }} onClick={sortMethod}/>
              </Container>
            ) : (
              <>
                {column.label}
              </>
              
            )}
            
          </a>
        ))}
        {stages && (
          <a className={styles.header__item}>
            Stage
          </a>
        )}
      </div>
      <div className={styles.table__content}>
        {data.map((item, rowIndex) => (
          <div className={styles.table__row} key={rowIndex}>
            {columns.map((column, colIndex) => (
              <a className={(column.key === "createdAt" || column.key.toString().includes("number")) ? styles.table__data__middle : (allowEllipsis ? styles.table__data__ellipsis : styles.table__data)} key={colIndex} onClick={() => handleClick(item)}>

                {String(item[column.key])}
              </a>
            ))}
            {stages && (
              <a className={styles.table__data} style={{
                display: "flex"
              }}>
                {/** @ts-ignore */}
                {stages.filter((stage) => stage.customernumber === Number(item["customernumber"])).map((stage, index) => (
                  <Container border_radius="10px" background={stage.color} key={index} padding="0.3em" margin="0 0 0.4em" display="flex" justify_content="space-between">
                    <Paragraph font_size="0.9em" cursor="auto">{stage.name}</Paragraph>
                    {/** @ts-ignore */}
                    <Paragraph font_size="0.9em" margin="0 0 0 0.3em" font_weight="700" onClick={() => handleDeleteStage(item["id"], stage.name)}>X</Paragraph>
                  </Container>
                ))}
              </a>
            )}
            <Modal id="deleteModal" headline="Löschen">
              <Paragraph font_size="1.1em" margin="1.7em 0 1.3em 0">Soll dieser Eintrag gelöscht werden?</Paragraph>
              <Button classes="btn btn-md btn-error" onClick={() => handleDelete()}>Löschen</Button>
            </Modal>
          </div>
        ))}
      </div>
    </div>
  )
}
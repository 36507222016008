import { IoIosSettings } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { IoHelpCircleOutline } from "react-icons/io5"
import { Button } from "../button/Button"

export const Navbar = () => {

    const navigate = useNavigate()

    return (
        <div className="navbar" style={{
            backgroundColor: "#84BD00"
        }}>
            <div className="flex-1">
                <a className="btn btn-ghost text-xl">digitalXL</a>
            </div>
            <div className="flex-none gap-2">
                <div>
                    <Button classes="btn btn-ghost btn-circle" tooltip="Hilfe benötigt? Klicke hier!" onClick={() => window.open("https://wiki.digitalxl.io/books/dxl-lykke", "_blank")}>
                        <IoHelpCircleOutline size={26}/>
                    </Button>
                    
                </div>
                <div className="dropdown dropdown-end">
                    <Button classes="btn btn-ghost btn-circle">
                        <IoIosSettings size={26} />
                    </Button>
                    <ul tabIndex={0} className="mt-4 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                        <li>
                            <a className="text-lg" onClick={() => navigate("/accounts")}>Accounts</a>
                        </li>
                        <li><a className="text-lg" onClick={() => navigate("/logout")}>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
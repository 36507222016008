import React from "react"
import { Customer } from "../../../interfaces/Customer"
import { Button } from "../../../components/button/Button"
import { Container } from "../../../components/container/Container"
import { Input } from "../../../components/input/Input"
import { Modal } from "../../../components/modal/Modal"
import { Paragraph } from "../../../components/paragraph/Paragraph"
import { AppLoadingStates } from "../../../data/AppLoadingStates"
import Select from 'react-select'
import { isNumber } from "../../../helper/ConvertToStrings"
import { changeData, getData } from "../../../helper/Utils"

interface props {
    customers: Customer[]
}

export const BrevoModal = ({ customers }: props) => {
    const [campaigns, setCampaigns] = React.useState<any[]>([])
    const [lists, setLists] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [listLoading, setListLoading] = React.useState<string>(AppLoadingStates[0])

    // true = add existing value to key
    // false = create new key with value
    const [showModalOptions, setShowModalOptions] = React.useState(true)

    // for all select fields in modal
    const [selectedCampaign, setSelectedCampaign] = React.useState<any>('')
    const [selectedList, setSelectedList] = React.useState<any>('')

    React.useEffect(() => {
        const getCampaigns = async () => {
            const result = await getData("/brevo")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCampaigns(result)
            setLoading(AppLoadingStates[200].default)
        }

        getCampaigns()
    }, [])

    const handleCampaignChange = async (choice: any) => {
        setSelectedCampaign(choice.value)
        setListLoading(AppLoadingStates[0])
        
        const result = await getData("/brevo/lists/" + choice.value)
        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        

        setLists(result)
        setListLoading(AppLoadingStates[200].default)
    }

    const handleUpdate = async () => {
        if(!(selectedCampaign || selectedList)) return alert("Ungültige Eingabe")

        const result = await changeData("/brevo/" + selectedCampaign,  "PUT", {
            listid:  selectedList.value,
            customers: customers
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        
        window.location.reload()
    }

    const handleCreate = async () => {
        const campaign: any = document.getElementById("brevoName")
        const subject: any = document.getElementById("brevoSubject")
        const list: any = document.getElementById("brevoList")

        if(!(campaign.value || subject.value || list.value)) return alert("Ungültige Eingabe")

        const result = await changeData("/brevo",  "POST", {
            name:  campaign.value,
            subject: subject.value,
            listname: list.value,
            customers: customers
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        
        window.location.reload()
    }

    return (
        <Modal id="loadBrevo" headline="Brevo">
            {loading === AppLoadingStates[200].default ? (
                <Container margin="1em" border_radius="10px" classes="bg-white">
                <Container display="flex" margin="1em 0 1em 0" cursor="pointer">
                    <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_left_radius="10px" height="45px" classes={showModalOptions ? "bg-white" : "bg-base-300"} onClick={() => setShowModalOptions(true)}>
                        <Paragraph font_size="1em">Hinzufügen</Paragraph>
                    </Container>
                    <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_right_radius="10px" height="45px" classes={showModalOptions ? "bg-base-300" : "bg-white"} onClick={() => setShowModalOptions(false)}>
                        <Paragraph font_size="1em">Neu</Paragraph>
                    </Container>
                </Container>
                {showModalOptions ? (
                    <Container padding="1.1em">
                        <Paragraph font_size="1.1em">Füge alle (gefilterten) Kunden zu einer Campaign hinzu. Wähle dabei auch die Liste, in die Kunden, falls sie noch nicht exsitieren, hinzugefügt werden</Paragraph>
                        <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Alle Campaign</Paragraph>
                        <Select options={campaigns} placeholder="Wähle eine Campaign" onChange={handleCampaignChange} />
                        {selectedCampaign && (
                            <>
                            {listLoading === AppLoadingStates[200].default ? (
                                <Container margin="1em 0 1em 0">
                                    <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Listennamen</Paragraph>
                                    <Select options={lists} placeholder="Wähle eine Option" onChange={(choice) => setSelectedList(choice)} />
                                </Container>
                            ) : (
                                <Paragraph font_size="1em" margin="1em 0 1em 0">{listLoading}</Paragraph>
                            )}
                            </>
                        )}
                        <Button margin="2em 0 1.6em 0" classes="btn btn-md btn-warning" onClick={() => handleUpdate()}>Hinzufügen</Button>
                    </Container>
                ) : (
                    <Container padding="1.1em">
                        <Paragraph font_size="1.1em">Alle Kunden, welche gerade in der Tabelle angezeigt werden, werden zu einer neuen Campaign hinzugefügt</Paragraph>
                        <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Campaign-Name</Paragraph>
                        <Input id="brevoName" placeholder="Name..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                        <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Listenname</Paragraph>
                        <Input id="brevoList" placeholder="Listenname..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                        <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Thema</Paragraph>
                        <Input id="brevoSubject" placeholder="Thema..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                        <Button margin="2em 0 1.6em 0" classes="btn btn-md btn-warning" onClick={() => handleCreate()}>Erstellen</Button>
                    </Container>
                )}

            </Container>
            ) : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}
        </Modal>
    )
}
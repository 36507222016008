import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Navitem } from "./components/Navitem";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import { Widget } from "../../components/widget/Widget";
import { openModal } from "../../helper/OpenModal";
import { Modal } from "../../components/modal/Modal";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Stage, stageColumns } from "../../interfaces/Stage";
import { Table } from "../../components/table/Table";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";

export const SettingsStagesPage = () => {
    const [stages, setStages] = React.useState<Stage[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getStages = async () => {
            const result = await getData("/stages")
            if(!result) return setLoading(AppLoadingStates[500])

            if(isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if(typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setStages(result)
            setLoading(AppLoadingStates[200].default)
        }

        getStages()
    }, [])

    const handleCreate = async () => {
        const name: any = document.getElementById("stageNameModal")
        const color: any = document.getElementById("stageColorModal")

        if(!(name.value || color.value)) return alert("Ungütlige Eingabe")
        const result = await changeData("/stages",  "POST", {
            "name": name.value,
            "color": color.value,
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleSearch = () => {

    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Einstellungen</Paragraph>
            <Container margin="2.5em 0 0 0">
                <Container display="flex">
                    <Navitem url="/settings/pair" text="Attribut Paare" active={false} first={true} />
                    <Navitem url="/settings/filter" text="Filter" active={false} />
                    <Navitem url="/settings/stage" text="Stages" active={true} />
                    <Navitem url="/settings/formulare" text="Formulare" active={false} last={true} />
                </Container>
                <Container background="#fff" padding="2em" border_bottom_left_radius="15px" border_bottom_right_radius="5px">
                    <Widget display="flex" align_items="center" justify_content="space-between">
                        <Container>
                            <Input placeholder="Suche nach Stage..." id="filterSearch" height="40px" borderRadius="10px" padding="0.7em" width="35vw" />
                            <Button classes="btn btn-warning btn-md" margin="0 0 0 2.5em" onClick={() => handleSearch()}>Anwenden</Button>
                        </Container>
                        <Button classes="btn btn-square btn-warning" tooltip="Erstellen" onClick={() => openModal("stageModal")}>
                            +
                        </Button>
                    </Widget>
                    <Modal id="stageModal" headline="Stage erstellen">
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Stagename</Paragraph>
                        <Input id="stageNameModal" placeholder="Name..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%"/>
                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Stagecolor</Paragraph>
                        <Input id="stageColorModal" placeholder="color..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" type="color"/>
                        <Button classes="btn btn-warning" onClick={() => handleCreate()} margin="1.3em 0 0 0">Erstellen</Button>
                    </Modal>
                    <Widget margin="2em 0 0 0">
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={stages} columns={stageColumns} deleteUrl="/stages"/>
                        ) : (
                            <Paragraph font_size="1em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
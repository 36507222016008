import React from "react"
import { useNavigate } from "react-router-dom"
import { changeData } from "../../../helper/Utils"
import { isNumber } from "../../../helper/ConvertToStrings"
import { Container } from "../../../components/container/Container"
import { TableColumn } from "../../../components/table/Table"
import { Paragraph } from "../../../components/paragraph/Paragraph"
import { PairSections } from "../../../data/PairSections"
import { Button } from "../../../components/button/Button"
import { Modal } from "../../../components/modal/Modal"

interface TableProps<T> {
    data: T[];
    columns: TableColumn<T>[];
    url: string
}

export const AttributeTable = <T,>({ data, columns, url }: TableProps<T>) => {
    const [deleteId, setDeleteId] = React.useState<number>()
    const navigate = useNavigate()

    const handleClick = (item: any) => {
        if (!item.id) return
        navigate(url + "/" + item.id)
    }

    const leftWidth = "14%"
    const rightWidth = "86%"
    const border = "1px solid #cccbca"

    return (
        <Container width="100%">
            <Container display="flex" width="100%" background="#84BD00" padding="18px 0">
                <Container padding="0.1em" width={leftWidth}>
                    
                </Container>
                <Container width={rightWidth} display="flex">
                    {columns.map((col, index) => (
                        <Paragraph key={index} font_size="1em" font_weight="600" flex="1 1 20%" text_align="center">
                            {col.label}
                        </Paragraph>
                    ))}
                </Container>
            </Container>
            <Container>
                {PairSections.map((item, index) => (
                    <Container display="flex" key={index} border_bottom={border}>
                        <Container width={leftWidth} display="flex" justify_content="center" align_items="center" padding="1em">
                            <Paragraph font_size="1em" font_weight="600" text_align="center">{item.label}</Paragraph>
                        </Container>
                        <Container width={rightWidth}>
                            {/** @ts-ignore */}
                            {data.filter((e) => e.section === item.label).map((row, i) => (
                                <Container display="flex" width="100%" key={i} onClick={() => handleClick(row)} padding="18px 0" zebra={true}>
                                    {columns.map((col, ind) => (
                                        <>
                                            {col.key === "createdAt" || col.key.toString().includes("number") ? (
                                                <Container key={ind} flex="1 1 20%">
                                                    <Paragraph text_align="center" font_size="1em" margin="0 0 0 0.7em">{String(row[col.key])}</Paragraph>
                                                </Container>
                                            ) : (
                                                <Container key={ind} flex="1 1 20%">
                                                    <Paragraph font_size="1em"  margin="0 0 0 0.7em">{String(row[col.key])}</Paragraph>
                                                </Container>
                                            )}
                                        </>
                                    ))}

                                </Container>
                            ))}
                        </Container>
                    </Container>
                ))}
                {/** @ts-ignore */}
                <Paragraph font_size="0.9em" margin="2em 0 0.8em 1em">Anzahl an nicht-zuordnungsbaren Attributen: {data.filter((item) => !item.section).length}</Paragraph>
            </Container>
        </Container>
    )
}
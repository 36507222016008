import { TableColumn } from "../components/table/Table";

export interface Placetelrouting {
    id?: number
    incoming_number: string
    target_number: string
}

// map to inferface, needed for table

export const placetelroutingsColumns: TableColumn<Placetelrouting>[] = [
    { key: 'incoming_number', label: 'Eingehende Nummer' },
    { key: 'target_number', label: 'Zielnummer' }
];
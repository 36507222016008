import { useNavigate, useParams } from "react-router-dom"
import { Page } from "../../components/page/Page"
import { Paragraph } from "../../components/paragraph/Paragraph"
import React from "react"
import { User } from "../../interfaces/User"
import { AppLoadingStates } from "../../data/AppLoadingStates"
import { changeData, getData } from "../../helper/Utils"
import { isNumber } from "../../helper/ConvertToStrings"
import { Container } from "../../components/container/Container"
import { Widget } from "../../components/widget/Widget"
import { Button } from "../../components/button/Button"
import { MdDelete } from "react-icons/md"
import { FormContainer } from "../../helper/GenerateFormInput"

export const SingleAccountPage = () => {
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [account, setAccount] = React.useState<User>()

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getAccount = async () => {
            const result = await getData("/users/id/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setAccount(result)
            setLoading(AppLoadingStates[200].default)
        }

        getAccount()
    }, [])

    const handleClick = async () => {
        const name: any = document.getElementById("accountName")
        if (!name.value) return alert("Keine gültige Eingabe")

        const result = await changeData("/users/" + id, "PUT", {
            "verified": true,
            "name": name.value
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        navigate("/accounts")
    }

    const handleDelete = async () => {
        const result = await changeData("/users/" + id, "DELETE", {})

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        navigate("/accounts")
    }


    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Account bearbeiten</Paragraph>
            {loading === AppLoadingStates[200].default ? (
                <Container>
                    <Widget display="flex" justify_content="space-between" margin="2em 0 2em 0" align_items="center">
                        <Paragraph font_size="1.1em">Möchten Sie den Account {account?.username} löschen?</Paragraph>
                        <Button classes="btn btn-md btn-primary" onClick={() =>  handleDelete()}>
                            <MdDelete size={24} />
                        </Button>
                    </Widget>
                    <Widget>
                        <Paragraph font_size="1.1em" margin="1em 0 0.8em 0">Um diesen Account zu verifizieren müssen Sie das nachfolgenden Feld ausfüllen</Paragraph>
                        <FormContainer label="Name" id="accountName" />
                        <Button margin="1.4em 0 0 0" classes="btn btn-primary btn-md" onClick={() => handleClick()}>Account verifizieren</Button>
                    </Widget>
                </Container>
            ) : (
                <Paragraph font_size="1.4em" margin="3em 0 0 0">{loading}</Paragraph>
            )}

        </Page>
    )
}
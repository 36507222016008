import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Placetelconfig } from "../../interfaces/Placetelconfig";
import { changeData, getData } from "../../helper/Utils";
import { isNumber, normalizeString } from "../../helper/ConvertToStrings";
import { Page } from "../../components/page/Page";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Container } from "../../components/container/Container";
import { Widget } from "../../components/widget/Widget";
import { Button } from "../../components/button/Button";
import { MdDelete } from "react-icons/md";
import { Input } from "../../components/input/Input";

export const SingePlacetelConfgPage = () => {
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [config, setConfig] = React.useState<Placetelconfig>()

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getConfig = async () => {
            const result = await getData("/placetelrouting/configs/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setConfig(result)
            setLoading(AppLoadingStates[200].default)
        }

        getConfig()
    }, [])

    const handleDelete = async () => {
        const result = await changeData("/placetelrouting/configs/" + id, "DELETE", {})
        if(!result) return alert("Ein Fehler ist aufgetreten")

        navigate("/placetel/configs")
    }

    const handleEdit = async () => {
        const short: any = document.getElementById("shortlyEdit")
        const target: any = document.getElementById("targetEdit")
        const comment: any = document.getElementById("placetelCommentEdit")
        const xmlaccept: any = document.getElementById("xmlContentEdit")
        const xmldecline: any = document.getElementById("xmlContentDeclineEdit")

        if (!(short.value || target.value || xmlaccept.value)) return alert("Ungültige Eingabe")

        const result = await changeData("/placetelrouting/configs/" + id, "PUT", {
            "targetnumber": target.value,
            "configType": short.value,
            "commentText": (comment.value ?? ""),
            "acceptXml": normalizeString(xmlaccept.value),
            "declineXml": normalizeString(xmldecline.value)
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Konfiguration bearbeiten</Paragraph>
            <Container margin="3em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Widget width="25%">
                    <Paragraph font_size="1.2em" font_weight="600">Konfiguration Löschen ?</Paragraph>
                    <Container display="flex" justify_content="center" align_items="center">
                        <Button classes="btn btn-ghost" onClick={() => handleDelete()}>
                            <MdDelete size={34} />
                        </Button>
                    </Container>
                </Widget>
                <Widget width="70%" padding="1em 2em 1em 2em">
                    {loading === AppLoadingStates[200].default ? (
                        <>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Kürzel</Paragraph>
                            <Input id="shortlyEdit" placeholder="Kürzel..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" value={config?.configType}/>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Ziel</Paragraph>
                            <Input id="targetEdit" placeholder="Ziel..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" value={config?.targetnumber}/>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Notiz (optional)</Paragraph>
                            <textarea id="placetelCommentEdit" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%"
                            }} placeholder="Notiz...">{config?.commentText}</textarea>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Accept-XML</Paragraph>
                            <textarea id="xmlContentEdit" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%",
                                minHeight: "11vh"
                            }} placeholder="XML...">{config?.acceptXml}</textarea>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Decline-XML</Paragraph>
                            <textarea id="xmlContentDeclineEdit" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%",
                                minHeight: "11vh"
                            }} placeholder="XML...">{config?.declineXml}</textarea>
                            <Button margin="1.6em 0 0 0" classes="btn btn-primary" onClick={() => handleEdit()}>Abschließen</Button>
                        </>
                    ) : (
                        <Paragraph font_size="1.2em">{loading}</Paragraph>
                    )}

                </Widget>
            </Container>
        </Page>
    )
}
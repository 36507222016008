import React from "react";
import { Page } from "../../components/page/Page";
import { Customer } from "../../interfaces/Customer";
import { Form } from "../../interfaces/Form";
import { FormField } from "../../interfaces/Formfield";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { isNumber } from "../../helper/ConvertToStrings";
import { changeData, getData } from "../../helper/Utils";
import { Container } from "../../components/container/Container";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/input/Input";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Widget } from "../../components/widget/Widget";
import Select from "react-select"
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { CgAsterisk } from "react-icons/cg";
import { Attributepair } from "../../interfaces/Attributepair";
import { Attribute } from "../../interfaces/Attribute";
import { CiWarning } from "react-icons/ci";
import { MdErrorOutline } from "react-icons/md";

export const CustomerFormPage = () => {
    const [customers, setCustomers] = React.useState<Customer[]>([])
    const [forms, setForms] = React.useState<Form[]>([])
    const [fields, setFields] = React.useState<FormField[]>([])
    const [pairs, setPairs] = React.useState<Attributepair[]>([])

    const [customersLoading, setCustomersLoading] = React.useState<string>(AppLoadingStates[0])
    const [formLoading, setFormLoading] = React.useState<string>(AppLoadingStates[0])
    const [fieldsLoading, setFieldsLoading] = React.useState<string>(AppLoadingStates[0])
    const [pairsLoading, setPairsLoading] = React.useState<string>(AppLoadingStates[0])

    const [selectedCustomer, setSelectedCustomer] = React.useState<any>()
    const [selectedForm, setSelectedForm] = React.useState<any>()

    // these are displayed
    const [activeFields, setActiveFields] = React.useState<FormField[]>([])
    const [conflicts, setConflicts] = React.useState<Array<{
        title: string,
        text: string
        type: string
    }>>([])
    const [savedFields, setSavedFields] = React.useState<Attribute[]>([])

    React.useEffect(() => {
        const getForm = async () => {
            const result = await getData("/forms")
            if (!result) return setFormLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setFormLoading(loadingState)

                return setFormLoading(AppLoadingStates[500])
            }

            setForms(result)
            setFormLoading(AppLoadingStates[200].default)
        }

        getForm()
    }, [])

    React.useEffect(() => {
        const getCustomers = async () => {
            const result = await getData("/customers")
            if (!result) return setCustomersLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setCustomersLoading(loadingState)

                return setCustomersLoading(AppLoadingStates[500])
            }

            setCustomers(result)
            setCustomersLoading(AppLoadingStates[200].default)
        }

        getCustomers()
    }, [])

    React.useEffect(() => {
        const getFields = async () => {
            const result = await getData("/formfields")
            if (!result) return setFieldsLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setFieldsLoading(loadingState)

                return setFieldsLoading(AppLoadingStates[500])
            }

            setFields(result)
            setFieldsLoading(AppLoadingStates[200].default)
        }

        getFields()
    }, [])

    React.useEffect(() => {
        const getParis = async () => {
            const result = await getData("/attributepairs")
            if (!result) return setPairsLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setPairsLoading(loadingState)

                return setPairsLoading(AppLoadingStates[500])
            }

            setPairs(result)
            setPairsLoading(AppLoadingStates[200].default)
        }

        getParis()
    }, [])

    React.useEffect(() => {
        const checkConflicts = async () => {
            let conflicts: any[] = []
            console.log("called")
            if (!selectedCustomer || activeFields.length === 0) return setConflicts(conflicts)

            const pairs: Attribute[] = await getData("/attributes/" + selectedCustomer.value)
            if (!pairs || isNumber(pairs)) return alert("Ein Fehler ist aufgetreten")

            pairs.map((item) => {
                if (activeFields.find((field) => field.key === item.key)) {
                    conflicts.push({
                        title: "Wert - Überschneidung",
                        text: `Attribut: (${item.key}, ${item.value}) bereits hinterlegt`,
                        type: "warning"
                    })
                }
            })
            setConflicts(conflicts)
        }

        checkConflicts()
    }, [selectedCustomer, selectedForm])

    const handleFormChange = (choice: any) => {
        setSelectedForm(choice)
        setActiveFields(fields.filter((item) => item.form_id === choice.value))
    }

    const handleFinish = async () => {
        // check required fields
        const requiredFields = activeFields.filter((item) => item.required)
        const diff = requiredFields.filter((item) => !savedFields.some((saved) => saved.key === item.key))

        if (diff.length > 0) return alert("Ungütlige Eingabe! Es fehlen required-Felder")

        const saved = [...savedFields]

        // get all textareas
        const textareas = document.querySelectorAll("textarea")
        textareas.forEach((item) => {
            if(item.value || item.value !== "") {
                saved.push({
                    key: activeFields[Number(item.id)].key!,
                    value: item.value,
                    customer_id: selectedCustomer.value,
                    public: false
                })
            }
        })

        const result = await changeData("/forms/attributes", "POST", {
            "pairs": saved
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        window.location.reload()
    }

    const handleCustomerNav = (direction: string) => {
        const index = customers.findIndex((item) => item.id === selectedCustomer.value)
        if (direction === "forward") {
            if (!customers[index + 1]) return alert("Ende")
            return setSelectedCustomer({ label: customers[index + 1].name, value: customers[index + 1].id })
        }

        // backward
        if (!customers[index - 1]) return alert("Ende")
        setSelectedCustomer({ label: customers[index - 1].name, value: customers[index - 1].id })

    }

    // check, if select or textarea
    const isTextarea = (key: string) => {
        const found = pairs.find((item) => item.key === key)
        return (found?.value ? false : true)
    }

    const handleSelectChange = (choice: any, index: number) => {
        const saved = [...savedFields]
        const key = activeFields[index].key
        const exists = saved.find((item) => item.key === key)
        if (exists) {
            exists.value = choice.value
            return setSavedFields(saved)
        }

        saved.push({
            key: key!,
            value: choice.value,
            customer_id: selectedCustomer.value,
            public: false
        })
        setSavedFields(saved)
    }

    const handleCustomerSelect = (choice: any) => {
        setSelectedCustomer(choice)
        setSavedFields([])
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Datenbearbeitung</Paragraph>
            <Container margin="3em 0 0 0">
                <Container display="flex" justify_content="space-between" align_items="flex-start">
                    <Container width="27%">
                        <Widget>
                            <Paragraph font_size="1.2em" textdecoration="underline">Auswahl:</Paragraph>
                            <Paragraph font_size="1.1em" margin="0.9em 0 0.6em 0">Kunde:</Paragraph>
                            {customersLoading === AppLoadingStates[200].default ? (
                                <Select onChange={(choice) => handleCustomerSelect(choice)} options={customers.map((item) => ({
                                    label: item.name,
                                    value: item.id
                                }))} value={selectedCustomer ?? ""} />
                            ) : (
                                <Paragraph font_size="1em" font_weight="600">{customersLoading}</Paragraph>
                            )}
                            <Paragraph font_size="1.1em" margin="1.5em 0 0.6em 0">Formular:</Paragraph>
                            {formLoading === AppLoadingStates[200].default ? (
                                <Select onChange={handleFormChange} options={forms.map((item) => ({
                                    label: item.name,
                                    value: item.id
                                }))} />
                            ) : (
                                <Paragraph font_size="1em" font_weight="600">{formLoading}</Paragraph>
                            )}
                        </Widget>
                        <Widget margin="2.3em 0 0 0">
                            <Paragraph font_size="1.2em" textdecoration="underline">Konflikte / Warnungen</Paragraph>
                            <Container border_radius="10px" background="#fff" max_height="300px" overflow="auto" padding="0.8em" margin="1.3em 0 0 0">
                                {conflicts.map((item) => (
                                    <Container border_radius="10px" border="1px solid #ededeb" padding="0.7em" margin="0 0 0.4em" display="flex" justify_content="space-between">
                                        <Container width="13%">
                                            {item.type === "warning" ? (
                                                <CiWarning size={26} color="orange"/>
                                            ) : (
                                                <MdErrorOutline size={22} color="red"/>
                                            )}
                                        </Container>
                                        <Container width="85%">
                                            <Paragraph font_size="1.1em" font_weight="600" margin="0 0 0.5em 0">{item.title}</Paragraph>
                                            <Paragraph font_size="1em">{item.text}</Paragraph>
                                        </Container>

                                    </Container>
                                ))}
                            </Container>
                        </Widget>
                    </Container>
                    <Widget width="69%">
                        <Container border_radius="10px" display="flex" justify_content="space-between" padding="1em" background="#fff" margin="0 0 2em 0">
                            {selectedCustomer ? (
                                <Container display="flex" justify_content="space-between" width="32%">
                                    <IoIosArrowBack size={22} color="gray" cursor="pointer" onClick={() => handleCustomerNav("backward")} />
                                    <Paragraph font_size="1em" margin="0 2em 0 2em 0">{selectedCustomer.label}</Paragraph>
                                    <IoIosArrowForward size={22} color="gray" cursor="pointer" onClick={() => handleCustomerNav("forward")} />
                                </Container>
                            ) : (
                                <Paragraph font_size="1em">Kunde: Bitte auswählen</Paragraph>
                            )}
                            {selectedForm ? (
                                <Paragraph font_size="1em">{selectedForm.label} - Formular</Paragraph>
                            ) : (
                                <Paragraph font_size="1em">Bitte Formular auswählen</Paragraph>
                            )}
                        </Container>
                        {pairsLoading === AppLoadingStates[200].default ? (
                            <Container display="flex" flexWrap="wrap" gap="1.3em" justify_content="space-between" align_items="flex-start">
                                {activeFields.map((item, index) => (
                                    <Container key={index} width={item.width + "%"}>
                                        <Container margin="0 0 0.5em 0" display="flex" justify_content="space-between">
                                            <Paragraph font_size="1.1em">{item.key}</Paragraph>
                                            {item.required && (
                                                <CgAsterisk color="red" size={20} />
                                            )}
                                        </Container>
                                        {isTextarea(item.key!) ? (
                                            <textarea style={{
                                                borderRadius: "10px",
                                                padding: "0.4em",
                                                height: "6vh",
                                                width: "100%"
                                            }} placeholder="Wert..." key={index} id={String(index)} />
                                        ) : (
                                            <Select options={pairs.filter((pair) => pair.key === item.key).map((pair) => ({
                                                label: pair.value,
                                                value: pair.value
                                            }))} key={index} onChange={(choice) => handleSelectChange(choice, index)} value={{ label: (savedFields.find(item => item.key === activeFields[index].key)?.value ?? ""), value: "" }} />
                                        )}
                                    </Container>
                                ))}
                            </Container>
                        ) : (
                            <Paragraph font_size="1em">{pairsLoading}</Paragraph>
                        )}
                        <Button onClick={() => handleFinish()} classes="btn btn-accent" margin="2.2em 0 0 0">Abschließen</Button>
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
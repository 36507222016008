import React from "react";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Page } from "../../components/page/Page";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { User, userColumns } from "../../interfaces/User";
import { getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Container } from "../../components/container/Container";
import { Widget } from "../../components/widget/Widget";
import { Table } from "../../components/table/Table";
import { Button } from "../../components/button/Button";

export const AccountPage = () => {

    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [users, setUsers] = React.useState<User[]>([])

    React.useEffect(() => {
        const getUsers = async () => {
            const result = await getData("/users")
            if(!result) return setLoading(AppLoadingStates[500])

            if(isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if(typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setUsers(result)
            setLoading(AppLoadingStates[200].default)
        }

        getUsers()
    }, [])

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="1.9em" font_weight="600">Accounts Übersicht</Paragraph>
            <Container display="flex" justify_content="space-between" align_items="flex-start" margin="2.4em 0 0 0">
                <Container width="20%">
                    <Widget>
                        <Paragraph font_size="1.3em">Anzahl Accounts</Paragraph>
                        <Container margin="2em 0 0 0" display="flex" justify_content="center">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-lg btn-circle btn-accent" font_size="1.6em">{users.length}</Button>
                            ) : (
                                <Paragraph font_size="1em">{loading}</Paragraph>
                            )}
                        </Container>
                    </Widget>
                    <Widget margin="3.4em 0 0 0">
                        <Paragraph font_size="1.3em">Nicht verifizierte Accounts</Paragraph>
                        <Container margin="2em 0 0 0" display="flex" justify_content="center">
                            <Button classes="btn btn-lg btn-circle btn-accent" font_size="1.6em">
                                {users.filter((item) => !item.verified).length}
                            </Button>
                        </Container>
                    </Widget>
                </Container>
                <Container width="77%">
                    <Widget>
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={users} columns={userColumns} baseUrl="/accounts/single"/>
                        ) : (
                            <Paragraph font_size="1.1em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
import React from "react"
import { Page } from "../../../components/page/Page"
import { Paragraph } from "../../../components/paragraph/Paragraph"
import { Container } from "../../../components/container/Container"
import { Widget } from "../../../components/widget/Widget"
import { Input } from "../../../components/input/Input"
import { Button } from "../../../components/button/Button"
import { AppLoadingStates } from "../../../data/AppLoadingStates"
import { changeData } from "../../../helper/Utils"
import { isNumber } from "../../../helper/ConvertToStrings"

export const CreateDashboardForm = () => {

    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    const handleCreate = async () => {
        const dashboardname: any = document.getElementById("username")
        const password: any = document.getElementById("password")
        if(!dashboardname.value || !password.value) return alert("keine gültigen Eingaben")

        const result = await changeData("/dashboards", "POST", {
            "dashboardname": dashboardname.value,
            "password": password.value
            
        })

        if (isNumber(result)) {
            const code: keyof typeof AppLoadingStates = result
            const loadingState = AppLoadingStates[code]

            // should be always true
            if (typeof loadingState === "string") return setLoading(loadingState)

            return setLoading(AppLoadingStates[500])
        }

        setLoading(AppLoadingStates[200].default)

    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Erstelle ein Dashboard</Paragraph>
            <Container display="flex" justify_content="space-between" margin="3em 0 0 0" align_items="flex-start">
                <Container width="41%">
                    <Widget>
                        <Paragraph font_size="1.7em" font_weight="600" textdecoration="underline" text_align="center" margin="1em 0 0 0">Formular</Paragraph>
                        <Paragraph font_size="1em" margin="1em 0 0 0" text_align="center">Bitte beachte: Der Name des Dashboards stellt sich aus Wunschname_kundennummer zusammen</Paragraph>
                        <Container margin="1.6em 0 0 0" display="flex" flex_direction="column" justify_content="center">
                            <Container>
                                <Paragraph font_size="1.4em">Dashboard Name</Paragraph>
                                <Input placeholder="Nutzername..." padding="1em" width="392px" margin="0.7em 0 0 0" borderRadius="10px" id="username"/>
                            </Container>
                            <Container margin="1em 0 0 0">
                                <Paragraph font_size="1.4em">Passwort</Paragraph>
                                <Input placeholder="Passwort..." padding="1em" width="392px" margin="0.7em 0 0 0" borderRadius="10px" id="password"/>
                            </Container>
                            <Container display="flex" justify_content="space-evenly" width="100%" margin="2em 0 0 0">
                                <Button classes="btn btn-md btn-info" tooltip="Leeres Dashboard erstellen" onClick={() => handleCreate()}>Erstellen</Button>
                            </Container>
                        </Container>
                    </Widget>
                </Container>
                <Container width="52%">
                    <Widget>
                        <Paragraph font_size="1.7em" font_weight="600">Vorschau</Paragraph>
                        <Paragraph font_size="1.2em" margin="1em 0 0 0">Dies kannst du dem Kunden direkt so weiterleiten, damit dieser sich seine Daten sicher ablegen kann</Paragraph>
                        <Container border_radius="15px" background="#fff" padding="1em" margin="1.6em 0 0 0">
                            {loading === AppLoadingStates[200].default ? (
                                <Container>
                                    <Paragraph font_size="1.8em">Nutzername: </Paragraph>
                                </Container>
                            ) : (
                                <>
                                {loading !== AppLoadingStates[0] && (
                                    <Paragraph font_size="1em">{loading}</Paragraph>
                                )}
                                </>
                            )}
                        </Container>
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
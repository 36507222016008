import React from "react";
import { Page } from "../../../components/page/Page";
import { Placetelconfig, placetelconfigColumns } from "../../../interfaces/Placetelconfig";
import { Placetelrouting, placetelroutingsColumns } from "../../../interfaces/Placetelrouting";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { changeData, getData } from "../../../helper/Utils";
import { isNumber } from "../../../helper/ConvertToStrings";
import { FaSync } from "react-icons/fa";
import { Button } from "../../../components/button/Button";
import { Container } from "../../../components/container/Container";
import { Input } from "../../../components/input/Input";
import { Modal } from "../../../components/modal/Modal";
import { Paragraph } from "../../../components/paragraph/Paragraph";
import { Table } from "../../../components/table/Table";
import { Widget } from "../../../components/widget/Widget";
import { openModal } from "../../../helper/OpenModal";

export const PlacetelroutingPage = () => {
    const [routings, setRoutings] = React.useState<Placetelrouting[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getRoutings = async () => {
            const result = await getData("/placetelrouting/routings")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setRoutings(result)
            setLoading(AppLoadingStates[200].default)
        }

        getRoutings()
    }, [])

    const handleSync = async () => {
        const result = await changeData("/placetelrouting/sync", "POST", {})
        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleSearch = async () => {

    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Placetel Routings</Paragraph>
            <Paragraph font_size="1.2em" margin="1em 0 0 0">Hier befinden sich alle synchronisierten und aktuell zugelassene Routings in Placetel</Paragraph>
            <Container margin="3em 0 0 0">
                <Widget display="flex" align_items="center">
                        <Paragraph font_size="1.1em" margin="0 2em 0 0.7em">Suche:</Paragraph>
                        <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="23vw" id="customerSearch" />
                        <Button classes="btn btn-md btn-accent" onClick={() => handleSearch()} margin="0 0 0 2em">Anwenden</Button>
                </Widget>
            </Container>
            <Container margin="3em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Container width="22%">
                    <Widget>
                        <Paragraph font_size="1.2em" font_weight="600">Synchronisieren</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1.5em 0 0 0">
                            <Button classes="btn btn-ghost" tooltip="Synchronisation" onClick={() => handleSync()}>
                                <FaSync size={34} />
                            </Button>
                        </Container>
                    </Widget>
                    <Widget margin="3em 0 0 0">
                        <Paragraph font_size="1.2em" font_weight="600">Anzahl Routings</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1.5em 0 0 0">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-accent btn-circle">
                                    <Paragraph font_size="1.5em">{routings.length}</Paragraph>
                                </Button>
                            ) : (
                                <Paragraph font_size="1.1em">{loading}</Paragraph>
                            )}

                        </Container>
                    </Widget>
                </Container>
                <Widget width="73%">
                    {loading === AppLoadingStates[200].default ? (
                        <>
                            <Table deleteUrl="/placetelrouting/routings" data={routings} columns={placetelroutingsColumns} />
                        </>
                    ) : (
                        <Paragraph font_size="1.2em">{loading}</Paragraph>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
import React from "react";
import { Page } from "../../components/page/Page";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Attributepair, attributepairColumns } from "../../interfaces/Attributepair";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Widget } from "../../components/widget/Widget";
import { Container } from "../../components/container/Container";
import { Navitem } from "./components/Navitem";
import { Input } from "../../components/input/Input";
import { Button } from "../../components/button/Button";
import { Table } from "../../components/table/Table";
import { Modal } from "../../components/modal/Modal";
import { openModal } from "../../helper/OpenModal";
import Select from 'react-select'
import { searchKeyPairs } from "../../helper/SearchAlgorithm";
import { FaFileExport } from "react-icons/fa6"
import { CSVLink, CSVDownload } from "react-csv"
import { PairSections } from "../../data/PairSections";

export const SettingsPairsPage = () => {

    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [pairs, setPairs] = React.useState<Attributepair[]>([])
    const [searchData, setSearchData] = React.useState<Attributepair[]>([])

    // used for input select in modal
    const [keys, setKeys] = React.useState<any[]>([])
    const [selectedKey, setSelectedKey] = React.useState<any>()
    const [selectedSection, setSelectedSection] = React.useState<any>()

    // true = add existing value to key
    // false = create new key with value
    const [showModalOptions, setShowModalOptions] = React.useState(true)

    React.useEffect(() => {
        const getPairs = async () => {
            const result = await getData("/attributepairs")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setPairs(result)
            setSearchData(result)
            // need to eliminate keys which occur twice or more
            const uniqueKeysSet = new Set();
            result.forEach((item: any) => {
                uniqueKeysSet.add(item.key);
            });

            setKeys(Array.from(uniqueKeysSet).map((uniqueKey) => ({
                label: uniqueKey,
                value: uniqueKey
            })));
            setLoading(AppLoadingStates[200].default)
        }

        getPairs()
    }, [])

    const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const query: any = document.getElementById("attributepairSearch")
        if (!query.value) return setSearchData(pairs)

        setSearchData(searchKeyPairs(pairs, query.value))
    }

    const handleCreateNew = async () => {
        const value: any = document.getElementById("valueInput")
        const key: any = document.getElementById("keyInput")
        const comment: any = document.getElementById("commentInput")
        const checkbox: any = document.getElementById("employeeUsage")

        if (!(key.value || selectedKey)) return alert("Ungültige Eingabe")
        const result = await changeData("/attributepairs", "POST", {
            "key": (selectedKey ? selectedKey.value : key.value),
            "value": (checkbox && checkbox.checked ? [] : (value.value ?? null)),
            "section": (selectedSection ? selectedSection.label : null),
            "comment": (comment.value ?? null)
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Einstellungen</Paragraph>
            <Container margin="2.5em 0 0 0">
                <Container display="flex">
                    <Navitem url="/settings/pair" text="Attribut Paare" active={true} first={true} />
                    <Navitem url="/settings/filter" text="Filter" active={false} />
                    <Navitem url="/settings/stage" text="Stages" active={false} />
                    <Navitem url="/settings/formulare" text="Formulare" active={false} last={true} />
                </Container>
                <Container background="#fff" padding="2em" border_bottom_left_radius="15px" border_bottom_right_radius="5px">
                    <Widget display="flex" align_items="center" justify_content="space-between">
                        <form onSubmit={handleSearch}>
                            <Input placeholder="Suche nach Key..." id="attributepairSearch" height="40px" borderRadius="10px" padding="0.7em" width="35vw" />
                        </form>
                        <Container display="flex">
                            {loading === AppLoadingStates[200].default ? (
                                <Button classes="btn btn-square btn-warning" tooltip="CSV-Export" margin="0 1.3em 0 0">
                                    <CSVLink data={searchData} filename={"Key-value-pairs.csv"}>
                                        <FaFileExport size={24} />
                                    </CSVLink>
                                </Button>
                            ) : (
                                <Paragraph font_size="1em">{loading}</Paragraph>
                            )}
                            <Button classes="btn btn-square btn-warning" tooltip="Erstellen" onClick={() => openModal("addAttributepairModal")}>
                                +
                            </Button>
                        </Container>
                    </Widget>
                    <Modal id="addAttributepairModal" headline="Attributpaar erstellen">
                        {loading === AppLoadingStates[200].default ? (
                            <Container margin="1em" border_radius="10px" classes="bg-white">
                                <Container display="flex" margin="1em 0 0 0" cursor="pointer">
                                    <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_left_radius="10px" height="45px" classes={showModalOptions ? "bg-white" : "bg-base-300"} onClick={() => setShowModalOptions(true)}>
                                        <Paragraph font_size="1em">Hinzufügen</Paragraph>
                                    </Container>
                                    <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_right_radius="10px" height="45px" classes={showModalOptions ? "bg-base-300" : "bg-white"} onClick={() => setShowModalOptions(false)}>
                                        <Paragraph font_size="1em">Neu</Paragraph>
                                    </Container>
                                </Container>
                                {showModalOptions ? (
                                    <Container padding="1.1em">
                                        <Paragraph font_size="1em">Füge einen neuen Wert zu einem bestehenden Attribut zu</Paragraph>
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Attribut</Paragraph>
                                        <Select placeholder="Attribut" options={keys} id="keyInput" onChange={(choice) => setSelectedKey(choice)} />
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Wert</Paragraph>
                                        <Input id="valueInput" placeholder="Wert..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                                        <Paragraph font_size="1em" margin="1em 0  0.8em 0">Bereich</Paragraph>
                                        <Select placeholder="Bereich..." options={PairSections} id="sectionInput" onChange={(choice) => setSelectedSection(choice)} />
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Kommentar (optional)</Paragraph>
                                        <Input id="commentInput" placeholder="Wert..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                                        <Button classes="btn btn-primary" margin="2.1em 0 0 0" onClick={() => handleCreateNew()}>Hinzufügen</Button>
                                    </Container>
                                ) : (
                                    <Container padding="1.1em">
                                        <Paragraph font_size="1em">Erstelle ein neues Attributpaar. Falls ein Freitext gewünscht ist, einfach den Wert leerlasen</Paragraph>
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Attribut</Paragraph>
                                        <Input id="keyInput" placeholder="Attribut..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                                        <Paragraph font_size="1em" margin="1em 0  0.8em 0">Bereich</Paragraph>
                                        <Select placeholder="Bereich..." options={PairSections} id="sectionInput" onChange={(choice) => setSelectedSection(choice)} />
                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Wert (optional)</Paragraph>
                                        <Container display="flex" justify_content="space-between">
                                            <Input id="valueInput" placeholder="Wert..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="65%" />
                                            <Container classes="" width="31%" display="flex" justify_content="space-between" align_items="flex-start">
                                                <Paragraph font_size="1em">Mitarbeiter</Paragraph>
                                                <input type="checkbox" className="checkbox" id="employeeUsage" />
                                            </Container>
                                        </Container>

                                        <Paragraph font_size="1em" margin="1em 0 0.8em 0">Kommentar (optional)</Paragraph>
                                        <Input id="commentInput" placeholder="Kommentar..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                                        <Button classes="btn btn-primary" margin="2.1em 0 0 0" onClick={() => handleCreateNew()}>Hinzufügen</Button>
                                    </Container>
                                )}
                            </Container>
                        ) : (
                            <Paragraph font_size="1em">{loading}</Paragraph>
                        )}
                    </Modal>
                    <Widget margin="2em 0 0 0">
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={searchData} columns={attributepairColumns} baseUrl="/settings/pair" allowEllipsis={true}/>
                        ) : (
                            <Paragraph font_size="1.2em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

import { FaShoppingBasket } from "react-icons/fa";
import { IoDocumentOutline, IoPeople, IoSettings } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { SlDocs } from "react-icons/sl";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { IoTicket } from "react-icons/io5";
import { CiRoute } from "react-icons/ci";
import { IoMdHome } from "react-icons/io";
import { FaWpforms } from "react-icons/fa6";

export const CustomSidebar = () => {
    const [activeModule, setActiveModule] = React.useState<string>("")

    React.useEffect(() => {
        const path = window.location.pathname
        const selectedItem = path.substring(1).split("/")
        setActiveModule(selectedItem[0])
    }, [])

    const navigate = useNavigate()

    const activeColor = "#bacbe0"

    return (
        <Sidebar>
            <Menu style={{
                padding: "0.5em"
            }}>
                <MenuItem icon={<IoMdHome size={26}/>} onClick={() => navigate("/homepage")} style={{
                    backgroundColor: (activeModule === "homepage" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Startseite</MenuItem>
                <MenuItem icon={<IoDocumentOutline size={26} />} onClick={() => navigate("/berichte")} style={{
                    backgroundColor: (activeModule === "berichte" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Berichte</MenuItem>
                <MenuItem icon={<LuLayoutDashboard size={26} />} onClick={() => navigate("/dashboard")} style={{
                    backgroundColor: (activeModule === "dashboard" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Dashboard</MenuItem>
                <MenuItem icon={<IoPeople size={26} />} onClick={() => navigate("/kunden")} style={{
                    backgroundColor: (activeModule === "kunden" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Kunden</MenuItem>
                <MenuItem icon={<FaWpforms size={26} />} onClick={() => navigate("/formulare/customer")} style={{
                    backgroundColor: (activeModule === "forms" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Formulare</MenuItem>
                <SubMenu label="Placetel" icon={<BsFillTelephoneForwardFill size={26} />} style={{
                    backgroundColor: (activeModule === "place" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>
                    <MenuItem icon={<IoSettings size={26} />} onClick={() => navigate("/place/configs")}>Konfigurationen</MenuItem>
                    <MenuItem icon={<CiRoute size={26} />} onClick={() => navigate("/place/routing")}>Routings</MenuItem>
                </SubMenu>
                <MenuItem icon={<IoTicket size={23} />} onClick={() => navigate("/liveagent")} style={{
                    backgroundColor: (activeModule === "liveagent" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Liveagent</MenuItem>
                <MenuItem icon={<IoSettings size={26} />} onClick={() => navigate("/settings/pair")} style={{
                    backgroundColor: (activeModule === "settings" ? activeColor : ""), 
                    borderRadius: "8px"
                }}>Einstellungen</MenuItem>
            </Menu>
        </Sidebar>
    )
}
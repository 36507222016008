export const openModal = (id: string) => {
    let el: any = document.getElementById(id)
    el.showModal()
}

export const closeModal = (id: string) => {
    let el: any = document.getElementById(id);
    if (el && typeof el.close === 'function') {
        el.close();
    } else {
        console.error(`Modal with id ${id} not found or does not support closing.`);
    }
};
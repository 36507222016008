import React from "react";
import { Page } from "../../components/page/Page";
import { FaPlus, FaSync } from "react-icons/fa";
import { Button } from "../../components/button/Button";
import { Container } from "../../components/container/Container";
import { Input } from "../../components/input/Input";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Table } from "../../components/table/Table";
import { Widget } from "../../components/widget/Widget";
import { Select } from "../../components/select/Select";
import { Report, reportColumns } from "../../interfaces/Report";
import { search } from "../../helper/SearchAlgorithm";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { FaSearch } from "react-icons/fa";
import { changeData, getData } from "../../helper/Utils";
import { isNumber } from "../../helper/ConvertToStrings";
import { Modal } from "../../components/modal/Modal";
import { openModal } from "../../helper/OpenModal";

export const ReportsPage = () => {

    const [data, setData] = React.useState<Report[]>([])
    const [searchData, setSearchData] = React.useState<Report[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getReports = async () => {
            const result = await getData("/reports")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setData(result)
            setSearchData(result)
            setLoading(AppLoadingStates[200].default)
        }

        getReports()
    }, [])

    const handleSearch = async () => {
        const query: any = document.getElementById("searchReports")
        const res = await search("name", query.value, data, reportColumns)
        if (!res) return alert("Ungültige Eingabe")

        setSearchData(res)
    }

    const handleRefresh = async () => {
        openModal("refreshModal")
        const result = await changeData("/reports/refresh", "POST", {})
        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Berichte</Paragraph>
            <Container margin="3em 0 0 0">
                <Widget>
                    <Container display="flex">
                        <Paragraph font_size="1.1em" margin="0 2em 0 0.7em">Suche:</Paragraph>
                        <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="23vw" id="searchReports" />
                        <Button classes="btn btn-md btn-accent" onClick={() => handleSearch()} margin="0 0 0 2em">Anwenden</Button>
                    </Container>
                    <Modal id="refreshModal" headline="Synchronisation">
                        <Paragraph font_size="1.1em">Die Berichte werden nun synchronisiert. Dies kann bis zu einer Minute dauern</Paragraph>
                        <Paragraph font_size="1.1em">Bitte warten, dieses Fenster schließt automatisch</Paragraph>
                        <Container display="flex" justify_content="center">
                            <span className="loading loading-spinner text-primary"></span>
                        </Container>
                    </Modal>
                </Widget>
            </Container>
            <Container display="flex" justify_content="space-between" margin="3em 0 0 0" align_items="flex-start">
                <Container width="20%">
                    <Widget>
                        <Paragraph font_size="1.5em">Angezeigte Berichte</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="3.2em">
                            <Button classes="btn btn-lg btn-circle btn-info" >
                                <Paragraph font_size="1.6em" text_align="center">{searchData.length}</Paragraph>
                            </Button>
                        </Container>
                    </Widget>
                    <Widget margin="3em 0 0 0">
                        <Paragraph font_size="1.2em" font_weight="600">Synchronisieren</Paragraph>
                        <Container display="flex" justify_content="center" align_items="center" margin="1.5em 0 0 0">
                            <Button classes="btn btn-ghost" tooltip="Git-Synchronisation" onClick={() => handleRefresh()}>
                                <FaSync size={34} />
                            </Button>
                        </Container>
                    </Widget>
                </Container>
                <Container width="76%">
                    <Widget max_height="680px" overflowy="auto">
                        {loading === AppLoadingStates[200].default ? (
                            <Table data={searchData} columns={reportColumns} />
                        ) : (
                            <Paragraph font_size="1.1em">{loading}</Paragraph>
                        )}
                    </Widget>
                </Container>
            </Container>
        </Page>
    )
}
import React from "react";
import { Page } from "../../../components/page/Page";
import { Paragraph } from "../../../components/paragraph/Paragraph";
import { Container } from "../../../components/container/Container";
import { Widget } from "../../../components/widget/Widget";
import { FaArrowRightLong } from "react-icons/fa6";
import { Button } from "../../../components/button/Button";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { changeData, getData } from "../../../helper/Utils";
import { isNumber, normalizeString } from "../../../helper/ConvertToStrings";
import { FaSync } from "react-icons/fa";
import { Input } from "../../../components/input/Input";
import { Table } from "../../../components/table/Table";
import { placetelconfigColumns } from "../../../interfaces/Placetelconfig";
import { Modal } from "../../../components/modal/Modal";
import { openModal } from "../../../helper/OpenModal";

export const PlactelconfigPage = () => {

    const [configs, setConfigs] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    React.useEffect(() => {
        const getSupportEntrys = async () => {
            const result = await getData("/placetelrouting/configs")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setConfigs(result)
            setLoading(AppLoadingStates[200].default)
        }

        getSupportEntrys()
    }, [])

    const handleSearch = async () => {

    }

    const handleCreateConfig = async () => {
        var short: any = document.getElementById("shortly")
        var target: any = document.getElementById("target")
        const comment: any = document.getElementById("placetelComment")
        const xmlaccept: any = document.getElementById("xmlContent")
        const xmldecline: any = document.getElementById("xmlContentDecline")

        if (!(short.value || target.value || xmlaccept.value)) return alert("Ungültige Eingabe")

        // modify number to be valid
        target.value = target.value.replaceAll(" ", "")
        target.value = target.value.replaceAll("+49", "")

        const result = await changeData("/placetelrouting/configs", "POST", {
            "targetnumber": target.value,
            "configType": short.value.toUpperCase(),
            "commentText": (comment.value ?? ""),
            "acceptXml": normalizeString(xmlaccept.value),
            "declineXml": normalizeString(xmldecline.value)
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            <Paragraph font_size="2.3em" font_weight="600">Placetel Konfigurationen</Paragraph>
            <Paragraph font_size="1.2em" margin="1em 0 0 0">Hier befinden sich alle hinterlegten Konfigurationen für unser Placetelrouting</Paragraph>
            <Container margin="3em 0 0 0">
                <Widget display="flex" align_items="center" justify_content="space-between">
                    <Container display="flex">
                        <Paragraph font_size="1.1em" margin="0 2em 0 0.7em">Suche:</Paragraph>
                        <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="23vw" id="customerSearch" />
                        <Button classes="btn btn-md btn-accent" onClick={() => handleSearch()} margin="0 0 0 2em">Anwenden</Button>
                    </Container>
                    <Button classes="btn btn-circle btn-accent" onClick={() => openModal("createNewConfig")} tooltip="Neue Konfiguration erstellen">+</Button>
                    <Modal id="createNewConfig" headline="Konfiguration erstellen">
                        <Container margin="1em">
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Kürzel</Paragraph>
                            <Input id="shortly" placeholder="Kürzel..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Zielnummer</Paragraph>
                            <Input id="target" placeholder="Ziel..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Notiz (optional)</Paragraph>
                            <textarea id="placetelComment" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%"
                            }} placeholder="Notiz..." />
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Accept-XML</Paragraph>
                            <textarea id="xmlContent" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%",
                                minHeight: "11vh"
                            }} placeholder="XML..." />
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Decline-XML</Paragraph>
                            <textarea id="xmlContentDecline" style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%",
                                minHeight: "11vh"
                            }} placeholder="XML..." />
                            <Button margin="1.6em 0 0 0" classes="btn btn-primary" onClick={() => handleCreateConfig()}>Hinzufügen</Button>
                        </Container>
                    </Modal>
                </Widget>
            </Container>
            <Container margin="3em 0 0 0">
                <Widget>
                    {loading === AppLoadingStates[200].default ? (
                        <>
                            <Table baseUrl="/place/single" data={configs} columns={placetelconfigColumns} />
                        </>
                    ) : (
                        <Paragraph font_size="1.2em">{loading}</Paragraph>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
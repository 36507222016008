import React from "react"

import styles from "./Page.module.scss"
import { Navbar } from "../nav/Navbar"
import { CustomSidebar } from "../nav/Sidebar"
import { Button } from "../button/Button"
import useLocalStorage from "../../hooks/useLocalstorage"
import { LuStickyNote } from "react-icons/lu";
import { Paragraph } from "../paragraph/Paragraph"

interface props {
    children: React.ReactNode
    showSidebar: boolean
}

export const Page = ({ children, showSidebar }: props) => {

    const [note, setNote] = useLocalStorage<string>('note', "");

    return (
        <div className={styles.page}>
            <Navbar />
            {showSidebar ? (
                <main className={styles.page__wrapper}>
                    <div className={styles.page__wrapper__sidebar}>
                        <CustomSidebar />
                    </div>
                    <div className={styles.page__wrapper__content}>
                        {children}
                    </div>
                    <div className="toast">
                        <div className="dropdown dropdown-top dropdown-end">
                            <div tabIndex={0} role="button" className="btn btn-circle btn-lg m-5" style={{
                                backgroundColor: "#84BD00"
                            }}>
                                <LuStickyNote />
                            </div>
                            <div tabIndex={0} className="dropdown-content z-[1] p-4 shadow bg-base-300 rounded-box w-72">
                                <Paragraph font_size="1.1em" textdecoration="underline" font_weight="600" margin="0 0 1em 0">Deine Notiz:</Paragraph>
                                <div className="whitespace-normal">{note}</div>
                            </div>
                        </div>
                    </div>
                </main>

            ) : (
                <main>
                    {children}
                </main>
            )}
        </div>
    )
}
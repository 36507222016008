import { http } from "./Http";

export const getData = async (url: string): Promise<any | number | null> => {
    const result:any = await http<any>({
        path: url
    });

    if(!result) {
        return null
    }

    if(result.ok && result.body) {
        return result.body.data;
    } else {
        return result.status;
    }
};

export const changeData = async (url: string, method: string, body: any): Promise<any | number | null> => {
    const result:any = await http<any>({
        path: url,
        method: method,
        body: body
    });
    

    if(!result) {
        return null
    }

    if(result.ok && result.body) {
        return result.body.data;
    } else {
        return result.status;
    }
};
import { TableColumn } from "../components/table/Table";

export interface Form {
    id: number
    stage_id: number
    description: string
    name: string
}

export const formColumns: TableColumn<Form>[] = [
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Beschreibung' },
];
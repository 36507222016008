export const AppLoadingStates = {
    500: "Ein Serverfehler ist aufgetreten",
    401: "Sie haben darauf keinen Zugriff",
    402: "Dieser Account wurde noch nicht verifziert",
    404: "Diese Seite exisitiert nicht",
    409: "Ihre Eingabe ist fehlerhaft",
    410: "Dieser Kunde existiert nicht",
    411: "Brevo API antwortet nicht",
    416: "Es ist keine passende Report-ID für den Systemstatus hinterlegt. Überprüfe die ID und die Zugriffsrechte des API-Accounts",
    419: "Bei diesem Kunden fehlen die Attribute Instanz oder ReportID",
    441: "Ein Kürzel konnte nicht zugeordnet werden",
    442: "Dieser Nutzername exisitiert bereits",
    200: {
        "default": "Erfolgreich",
        "register": "Account anfrage erfolgreich gesendet. Dein Account muss zuerst verifiziert werden, bevor du ihn nutzen kannst",
        "login": "Sie werden umgehend weitergeleitet",
        "sync": "Die Synchronisation war erfolgreich. Sie werden umgehend weitergeleitet"
    },
    0: "Lade Daten...",
    1: "Dies kann ein Moment dauern, bitte warten..."
}
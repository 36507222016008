import React from "react"
import { Page } from "../../components/page/Page"
import { Paragraph } from "../../components/paragraph/Paragraph"
import { User } from "../../interfaces/User"
import { AppLoadingStates } from "../../data/AppLoadingStates"
import { getData } from "../../helper/Utils"
import { isNumber } from "../../helper/ConvertToStrings"
import useLocalStorage from "../../hooks/useLocalstorage"
import { Container } from "../../components/container/Container"
import { Widget } from "../../components/widget/Widget"
import { Button } from "../../components/button/Button"
import { openModal } from "../../helper/OpenModal"
import { Modal } from "../../components/modal/Modal"

export const HomePage = () => {
    const [user, setUser] = React.useState<User>()
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])

    const [note, setNote] = useLocalStorage<string>('note', "test");

    React.useEffect(() => {
        const getCustomer = async () => {
            const result = await getData("/users/single")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setUser(result)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomer()
    }, [])

    const saveNote = () => {
        const note:any = document.getElementById("note")
        setNote(note.value)

        window.location.reload()
    }

    return (
        <Page showSidebar={true}>
            {loading === AppLoadingStates[200].default ? (
                <Paragraph font_size="2.2em" font_weight="600">Willkommen zurück, {user?.name}</Paragraph>
            )  : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}
            <Container margin="3em 0 0 0" display="flex" justify_content="space-between">
                <Widget width="20%">
                    <Paragraph font_size="1.1em" font_weight="600" textdecoration="underline">Schnell Notiz:</Paragraph>
                    <Paragraph font_size="1em" margin="1em 0 2em 0">Hier kannst du eine schnell Notiz anlegen und jederzeit, auf jeder Seite rechts unten öffnen</Paragraph>
                    <Container display="flex" justify_content="center" align_items="center">
                        <Button classes="btn btn-circle btn-accent" tooltip="Schnellnotiz erstellen" onClick={() => openModal("editNoteModal")}>+</Button>
                        <Modal headline="Schnellnotiz" id="editNoteModal">
                            <Paragraph font_size="1em" margin="2em 0 0.5em 0">Notiz:</Paragraph>
                            <textarea style={{
                                padding: "0.4em",
                                borderRadius: "10px",
                                border: "1px solid gray",
                                width: "100%",
                                minHeight: "13vh"
                            }} id="note" defaultValue={note} />
                            <Button classes="btn btn-primary btn-sm" margin="2em 0 0.6em 0" onClick={() => saveNote()}>Abspeichern</Button>
                        </Modal>
                    </Container>
                </Widget>
            </Container>
        </Page>
    )
}
export const PairSections = [
    { value: "Customer Journey", label: "Customer Journey" },
    { value: "Artikelmanagement", label: "Artikelmanagement" },
    { value: "Beschaffungsprozesse", label: "Beschaffungsprozesse" },
    { value: "E-Commerce Plattform", label: "E-Commerce Plattform" },
    { value: "Inventory & Returns", label: "Inventory & Returns" },
    { value: "Daten & Analyse", label: "Daten & Analyse" },
    { value: "Profildaten", label: "Profildaten" },
    { value: "Finanzen & Zahlungsprozesse", label: "Finanzen & Zahlungsprozesse" },
    { value: "Software", label: "Software" },
    { value: "Sonstges", label: "Sonstiges" },
]
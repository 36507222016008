import { TableColumn } from "../components/table/Table"
import { Attribute } from "../interfaces/Attribute";
import { Attributepair } from "../interfaces/Attributepair";


/**
 * 
 * @param defaultColumn default column to search query
 * @param query query text
 * @param data dataset
 * @param translation for searching after label and not after key 
 * @param secondTranslation used for second dataset to search for column -> attributes for customer
 * 
 * @returns search dataset
 */
export const search = (defaultColumn: string, query: string, data: any[], translation: any[]): any[] | undefined => {
    if (query.length === 0) return data

    let columnToSearch: any;
    if (query.includes(" ") && query.includes(":")) {
        const split = query.split(" ");

        let ergebnis: any[];
        if (split.includes("&")) {
            const split1 = query.split(":");
            const key = split1[0];
            const values = split1[1].split("&");

            ergebnis = data.filter((dataset) => {
                columnToSearch = translation.find((item) => item.label.toLowerCase().includes(key.toLowerCase()));
                if (!columnToSearch) return false;

                return values.every((value) => {
                    return dataset[columnToSearch!.key].toLowerCase().includes(value.toLowerCase());
                });
            });
        } else {
            ergebnis = data.filter((dataset) => {
                const vals = split.every((req) => {
                    const [key, value]: any = req.split(":");
                    columnToSearch = translation.find((item) => item.label.toLowerCase().includes(key.toLowerCase()))
                    if (!columnToSearch) return false
                    return dataset[columnToSearch!.key].toLowerCase().includes(value.toLowerCase());
                })

                return vals
            });
        }

        //if(ergebnis.length === 0) return undefined

        return ergebnis

    } else if (query.includes(":")) {
        if (query.includes("&")) {
            const split1 = query.split(":");
            const key = split1[0];
            const values = split1[1].split("&");

            const ergebnis = data.filter((dataset) => {
                const columnToSearch = translation.find((item) => item.label.toLowerCase().includes(key.toLowerCase()));
                if (!columnToSearch) return false;

                return values.every((value) => {
                    return dataset[columnToSearch!.key].toLowerCase().includes(value.toLowerCase());
                });
            });

            return ergebnis
        } else {
            const split = query.split(":")
            columnToSearch = translation.find((item) => item.label.toLowerCase().includes(split[0].toLowerCase()))
            if (!columnToSearch) return undefined

            return data.filter((item) => item[columnToSearch.key!].toLowerCase().includes(split[1].toLocaleLowerCase()))
        }
    }

    // check if column exists
    if (!(data.every(item => !!item.name))) return undefined

    return data.filter((item) => item[defaultColumn].toLowerCase() === query.toLocaleLowerCase())
}

/**
 * 
 * @param dataset1 main dataset for searching
 * @param dataset2 dataset for customer attributes
 * @param query search query
 * @returns array with matches
 */
export const complexSearch = async (dataset1: any[], dataset2: any[], query: string) => {
    //  sometimes it throws an error due to react issues
    let results: any[] = [];
    try {
        if (query === "") return dataset1

        if (query.includes(" ") && query.includes(":")) {
            const split = query.split(" ")

            for (var str in split) {
                const splitted = split[str].split(":")
                if (splitted[1].includes("&")) {

                    const values = splitted[1].split("&")

                    if (results.length === 0) results = dataset1
                    if (dataset1[0][splitted[0].toLowerCase()]) {
                        for (var value in values) {
                            value = values[value].replaceAll("-", " ")
                            results = results.filter((item) => item[splitted[0].toLowerCase()].toLowerCase().includes(value.toLowerCase()));
                        }
                    } else {
                        let filtered: any[] = []
                        for (var val in values) {
                            value = values[val].replaceAll("-", " ").toLowerCase()
                            dataset2.filter((item) => item.key.toLowerCase() === splitted[0].toLowerCase() && item.value.toLowerCase().includes(value.toLowerCase())).map((item) => filtered.push(item))
                        }

                        // get all items which contain both
                        // map customer_id-> occurences
                        var occurrences: Record<number, number> = {};
                        filtered.forEach((item) => {
                            const num = item.customer_id;
                            occurrences[num] = (occurrences[num] || 0) + 1;
                        });

                        const filteredIds = filtered.filter((item) => occurrences[item.customer_id] === 2).map((item) => item.customer_id);
                        if (filteredIds.length === 0) return []

                        results = results.filter((item) => filteredIds.includes(item.id))
                    }

                } else {
                    // check if column exists in dataset1
                    splitted[1] = splitted[1].replaceAll("-", " ")
                    if (dataset1[0][splitted[0].toLowerCase()]) {
                        if (results.length === 0) results = dataset1
                        results = results.filter((item) => item[splitted[0].toLowerCase()].toLowerCase().includes(splitted[1].toLowerCase()));
                    } else {
                        // this is used for searching through customer attributes
                        if (results.length === 0) results = dataset1
                        var filteredIds = dataset2.filter((item) => item.key.toLowerCase() === splitted[0].toLowerCase() && item.value.toLowerCase().includes(splitted[1].toLowerCase())).map((item) => item.customer_id);
                        if (filteredIds.length === 0) return []

                        results = results.filter((item) => filteredIds.includes(item.id))

                    }
                }
            }

        } else if (query.includes(":")) {
            const split = query.toLowerCase().split(":")

            if (split[1].includes("&")) {
                const values = split[1].split("&")

                if (dataset1[0][split[0]]) {
                    // filter after every value part by part
                    results = dataset1
                    for (var val in values) {
                        value = values[val].replaceAll("-", " ").toLowerCase()
                        results = results.filter((item) => item[split[0]].toLowerCase().includes(value));
                    }

                } else {
                    let filtered: any[] = []
                    for (var val in values) {
                        value = values[val].replaceAll("-", " ").toLowerCase()
                        dataset2.filter((item) => item.key.toLowerCase() === split[0] && item.value.toLowerCase() === value).map((item) => filtered.push(item))
                    }

                    // get all items which contain both
                    // map customer_id-> occurences
                    var occurrences: Record<number, number> = {};
                    filtered.forEach((item) => {
                        const num = item.customer_id;
                        occurrences[num] = (occurrences[num] || 0) + 1;
                    });

                    const filteredIds = filtered.filter((item) => occurrences[item.customer_id] === 2).map((item) => item.customer_id);
                    results = dataset1.filter((item) => filteredIds.includes(item.id))
                }
            } else {
                split[1] = split[1].replaceAll("-", " ")
                // check if column exists in dataset1
                if (dataset1[0][split[0].toLowerCase()]) {
                    results = dataset1.filter((item) => item[split[0].toLowerCase()].toLowerCase().includes(split[1].toLowerCase()));
                } else {
                    // this is used for searching through customer attributes
                    const filteredIds = dataset2.filter((item) => item.key.toLowerCase() === split[0] && item.value.toLowerCase() === split[1]).map((item) => item.customer_id);
                    results = dataset1.filter((item) => filteredIds.includes(item.id))
                }
            }
        } else {
            // only value was given, no search column
            // search in every column 
            query = query.toLowerCase()
            const split = query.split("&")

            split.map((word) => {
                for (const index in dataset1) {
                    const item = dataset1[index]
                    for (const key in item) {
                        if(String(item[key]).toLowerCase().includes(word)) {
                            results.push(item)
                            break
                        }
                    }
                }
    
                for (const index in dataset2) {
                    const item = dataset2[index]
                    for (const key in item) {
                        if(String(item[key]).toLowerCase().includes(word)) {
                            results.push(dataset1.find((customer) => customer.id === item.customer_id))
                        }
                    }
                }
            })

            if(split.length > 1) {
                // need to check occurences
                var occurrences: Record<number, number> = {};
                results.forEach((item) => {
                    const num = item.id;
                    occurrences[num] = (occurrences[num] || 0) + 1;
                });

                results = dataset1.filter((item) => occurrences[item.id] > 1)
            }
        }
    } catch (err) {
        alert("Ein Fehler ist aufgetreten, bitte lade einmal die Seite neu und schreibe die Anfrage nochmal")
    }

    return results;
}

export const searchKeyPairs = (data: any[], query: string) => {
    if (query === "") return data

    return data.filter((item) => item.key.toLowerCase().includes(query.toLowerCase()))
}
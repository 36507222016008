import React from "react"
import { AppLoadingStates } from "../../../data/AppLoadingStates"
import { Customer } from "../../../interfaces/Customer"
import { useNavigate, useParams } from "react-router-dom"
import { changeData, getData } from "../../../helper/Utils"
import { isNumber } from "../../../helper/ConvertToStrings"
import { Page } from "../../../components/page/Page"
import { Paragraph } from "../../../components/paragraph/Paragraph"
import { Container } from "../../../components/container/Container"
import { Widget } from "../../../components/widget/Widget"
import { MdDelete, MdEdit } from "react-icons/md"
import { Button } from "../../../components/button/Button"
import { AttributeModal } from "../forms/components/AttributeModal"
import { openModal } from "../../../helper/OpenModal"
import { Attribute, attributeColumns } from "../../../interfaces/Attribute"
import { Table } from "../../../components/table/Table"
import { Modal } from "../../../components/modal/Modal"
import { Input } from "../../../components/input/Input"
import { Alert } from "../../../components/alert/Alert"
import { FaEye } from "react-icons/fa";
import { Stage } from "../../../interfaces/Stage"
import { FaLongArrowAltRight } from "react-icons/fa"
import { searchKeyPairs } from "../../../helper/SearchAlgorithm"
import { AttributeTable } from "../components/AttributeTable"

export const SingleCustomerPage = () => {
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [customer, setCustomer] = React.useState<Customer>()

    const [attributeLoading, setAttributeLoading] = React.useState<string>(AppLoadingStates[0])
    const [attributes, setAttributes] = React.useState<Attribute[]>([])
    const [searchData, setSearchData] = React.useState<Attribute[]>([])

    const [logo, setLogo] = React.useState<string>()

    const [stageLoading, setStageLoading] = React.useState<string>(AppLoadingStates[0])
    const [stages, setStages] = React.useState<any[]>([]) // any becausue main stage attributes arae in datavalues and junction_id is outside

    const { id } = useParams()

    const navigate = useNavigate()

    React.useEffect(() => {
        const getCustomer = async () => {
            const result = await getData("/customers/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCustomer(result.dataValues)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomer()
    }, [])

    React.useEffect(() => {
        const getAttributes = async () => {
            const result = await getData("/attributes/" + id)
            if (!result) return setAttributeLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setAttributeLoading(loadingState)

                return setAttributeLoading(AppLoadingStates[500])
            }

            setAttributes(result)
            setSearchData(result)

            const logoattribute = result.find((item: Attribute) => item.key === "Firmenlogo")
            if(logoattribute) setLogo(logoattribute.value)

            setAttributeLoading(AppLoadingStates[200].default)
        }

        getAttributes()
    }, [])

    React.useEffect(() => {
        const getStages = async () => {
            const result = await getData("/customers/stages/" + id)
            if (!result) return setStageLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setStageLoading(loadingState)

                return setStageLoading(AppLoadingStates[500])
            }

            setStages(result)
            setStageLoading(AppLoadingStates[200].default)
        }

        getStages()
    }, [])

    const handleFinish = async () => {
        const instance:any = document.getElementById("xentralInstance")
        const health:any = document.getElementById("healtReportId")
        //const token:any = document.getElementById("apiToken")
        const username:any = document.getElementById("apiUsername")
        const password:any = document.getElementById("apiPassword")

        if(!(instance.value || health .value || username.value || password.value)) return alert("Ungültige Eingabe")

        const result = await changeData("/customers/" + id, "PUT", {
            "instance": instance.value,
            "healthReportId": health.value,
            "dpassword": password.value,
            "dusername": username.value
        })

        if(!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleDelete = async (id: number) => {
        const result = await changeData("/customers/stage/persistent/" + id, "DELETE", {})

        if(isNumber(result) ||  !result) return alert("Ein Fehler ist aufgetreten")
        window.location.reload()
    }

    const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const query: any = document.getElementById("attributeSearch")
        if (!query.value) return setSearchData(attributes)
    
        setSearchData(searchKeyPairs(attributes, query.value))
    }

    return (
        <Page showSidebar={true}>
            <Container display="flex" align_items="center">
                <Paragraph font_size="2.3em" font_weight="600">Kunde bearbeiten</Paragraph>
                {attributeLoading === AppLoadingStates[200].default && (
                    <Container margin="0 0 0 1.6em">
                        {logo && (            
                            <img src={logo} style={{
                                maxHeight: "5vh",
                                maxWidth: "12vw"
                            }}/>
                        )}
                    </Container>
                )}
            </Container>
            {!(loading === AppLoadingStates[200].default && customer?.healthReportId) && (
                <Alert text="Dieses Kundenprofil ist noch unvollständig! Bitte ergänze es mit den nötigen Daten" margin="2em 0 2.6em 0" modalId="finishCustomer" />
            )}
            <Modal id="finishCustomer" headline="Kunde vervollständigen">
                <Container padding="1.1em">
                    <Paragraph font_size="1em" margin="1em 0 0.8em 0">Xentral-Instanz</Paragraph>
                    <Input id="xentralInstance" placeholder="Instanz..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                    <Paragraph font_size="1em" margin="1em 0 0.8em 0">Healthreport ID</Paragraph>
                    <Input id="healtReportId" placeholder="Id..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                    <Paragraph font_size="1em" margin="1em 0 0.8em 0">Api Account Username</Paragraph>
                    <Input id="apiUsername" placeholder="Username..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                    <Paragraph font_size="1em" margin="1em 0 0.8em 0">Api Account Password</Paragraph>
                    <Input id="apiPassword" placeholder="Password..." padding="0.4em" borderRadius="10px" border="1px solid gray" width="100%" />
                    <Button classes="btn btn-primary" margin="2.1em 0 0 0" onClick={() => handleFinish()}>Vervollständigen</Button>
                </Container>
            </Modal>
            <Container display="flex" justify_content="space-between" margin="2em 0 0 0" align_items="flex-start">
                <Widget width="25%" display="flex" justify_content="space-between" align_items="center">
                    {loading === AppLoadingStates[200].default ? (
                        <>
                            <Paragraph font_size="1.2em" font_weight="600" whitespace="nowrap" overflow="hidden" textoverflow="ellipsis" width="75%">{customer?.name}</Paragraph>
                            <Container display="flex" justify_content="space-between" width="20%">
                                <Button classes="btn  btn-circle btn-warning" tooltip="Eigenschaft hinzufügen" onClick={() => openModal("customerAttributes")}>+</Button>
                                <AttributeModal id={Number(id)} />
                            </Container>
                        </>

                    ) : (
                        <Paragraph font_size="1em">{loading}</Paragraph>
                    )}
                </Widget>
                <Widget display="flex" align_items="center" width="70%" justify_content="space-between">
                    <form onSubmit={handleSearch}>
                        <Input placeholder="Suche..." height="40px" borderRadius="9px" padding="0.3em" width="25vw" id="attributeSearch" />
                    </form>
                    <Container>
                        <Button classes="btn btn-warning btn-circle" margin="0 1.5em 0 0" tooltip="Zur Kundenansicht" onClick={() => navigate("/kunden/view/" + id)}>
                            <FaEye size={18}/>
                        </Button>
                        <Button classes="btn btn-warning" tooltip="Zur Übersicht" onClick={() => navigate("/kunden/status/" + id)}>Systemhealth</Button>
                    </Container>
                </Widget>
            </Container>
            <Widget margin="3em 0 0 0">
                {(loading === AppLoadingStates[200].default && attributeLoading === AppLoadingStates[200].default) ? (
                    <Container margin="0.3em 0 0 0" max_height="65vh" overflow="auto">
                        <AttributeTable data={searchData} columns={attributeColumns} url={`/kunden/single/${id}/attribute`}/>
                    </Container>
                ) : (
                    <Paragraph font_size="1em" margin="1em 0 0 0">{attributeLoading}</Paragraph>
                )}
            </Widget>
            <Widget margin="3em 0 0 0">
                <Paragraph font_size="1.1em" textdecoration="underline" font_weight="600">Historie</Paragraph>
                {stageLoading === AppLoadingStates[200].default ? (
                    <Container display="flex" flexWrap="wrap" margin="1.2em">
                        {stages.map((item, index) => (
                            <Container key={index} display="flex" margin="0 1.5em 0 0">
                                <FaLongArrowAltRight size={26}/>
                                <Container border_radius="8px" background={item.dataValues.color} minwidth="6vw" padding="0.8em" margin="0 0 0 1.1em" onClick={() => openModal(`customerStage${index}`)}>
                                    <Paragraph font_size="1em" text_align="center">{item.dataValues.name}</Paragraph>
                                </Container>
                                <Modal headline="Stage aus Kundenhistorie löschen?" id={`customerStage${index}`}>
                                    <Paragraph font_size="1em" margin="1em 0 1.7em 0">Dieser Stage wird aus der Historie dauerhaft gelösch</Paragraph>
                                    <Button classes="btn btn-error" onClick={() => handleDelete(item.junction_id)}>Löschen</Button>
                                </Modal>
                            </Container>
                        ))}
                    </Container>
                ) : (
                    <Paragraph font_size="1em">{stageLoading}</Paragraph>
                )}
            </Widget>
        </Page>
    )
}
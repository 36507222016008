import React from "react";
import { Page } from "../../components/page/Page";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/paragraph/Paragraph";
import { Input } from "../../components/input/Input";
import { Widget } from "../../components/widget/Widget";
import { Button } from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { changeData } from "../../helper/Utils";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { isNumber } from "../../helper/ConvertToStrings";

export const LoginPage = () => {

    const [loading, setLoading] = React.useState<string>()
    const navigate = useNavigate()

    const handleLogin = async () => {
        const username: any = document.getElementById("usernameLogin")
        const password: any = document.getElementById("passwordLogin")

        if(!username.value || !password.value) return alert("Keine gültigen Eingaben")

        const result = await changeData("/login", "POST", {
            "username": username.value,
            "password": password.value
        })

        if(!result) return setLoading(AppLoadingStates[500])
        if(isNumber(result)) {
            const code: keyof typeof AppLoadingStates = result
            const loadingState = AppLoadingStates[code]

            // should be always true
            if(typeof loadingState === "string") return setLoading(loadingState)

            return setLoading(AppLoadingStates[500])
        }

        setLoading(AppLoadingStates[200].login)
        setTimeout(() => {
            navigate("/homepage")
        }, 2200)
    }

    return (
        <Page showSidebar={false}>
            <Container display="flex" justify_content="center" flex_direction="column" margin="5em 0 1em 0" width="100%">
                <Widget padding="2em">
                    <Paragraph font_size="2.5em" font_weight="600" text_align="center">Login</Paragraph>
                    <Container margin="2.2em 0 0 0">
                        <Paragraph font_size="1.4em" margin="0 0 0.4em 0">Nutzername</Paragraph>
                        <Input placeholder="Nutzername..." height="60px" width="380px" borderRadius="10px" padding="1.2em" id="usernameLogin"/>
                    </Container>
                    <Container margin="1.6em 0 0 0">
                        <Paragraph font_size="1.4em" margin="0 0 0.4em 0">Passwort</Paragraph>
                        <Input placeholder="Passwort..." type="password" height="60px" width="380px" borderRadius="10px" padding="1.2em" id="passwordLogin"/>
                    </Container>
                    <Container margin="1.5em 0 0 0">
                        <Paragraph color="blue" font_size="1.2em" text_align="left" textdecoration="underline" cursor="pointer" onClick={()  => navigate("/register")}>Noch keinen Account ? Registrieren!</Paragraph>
                    </Container>
                    <Container margin="1.6em 0 0 0">
                        <Button classes="btn btn-md btn-warning" onClick={handleLogin}>Login</Button>
                    </Container>
                    {loading && (
                        <Container margin="1em 0 0 0" width="100%" word_break="break-word">
                            {loading === AppLoadingStates[200].login ? (
                                <>
                                    <Paragraph font_size="1.4em" color="green">{loading}</Paragraph>
                                </>
                            ) : (
                                <>
                                    <Paragraph font_size="1.4em" color="red">{loading}</Paragraph>
                                </>
                            )}
                        </Container>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
import React from "react";
import { Page } from "../../../components/page/Page";
import { useNavigate, useParams } from "react-router-dom";
import { Customer } from "../../../interfaces/Customer";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { isNumber } from "../../../helper/ConvertToStrings";
import { changeData, getData } from "../../../helper/Utils";
import { Paragraph } from "../../../components/paragraph/Paragraph";
import { Container } from "../../../components/container/Container";
import { Widget } from "../../../components/widget/Widget";
import { MdDelete } from "react-icons/md";
import { Button } from "../../../components/button/Button";
import { Attribute } from "../../../interfaces/Attribute";
import { Attributepair } from "../../../interfaces/Attributepair";
import Select from 'react-select'

export const CustomerAttributEditPage = () => {
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [customer, setCustomer] = React.useState<Customer>()

    const [loadingAttribute, setLoadingAttribute] = React.useState<string>(AppLoadingStates[0])
    const [attribute, setAttribute] = React.useState<Attribute>()

    const [loadingAllAttributepairs, setAllLoadingAttributepairs] = React.useState<string>(AppLoadingStates[0])
    const [allAttributepairs, setAllAttributepairs] = React.useState<Attributepair[]>([])

    // all possible values to show in select
    const [loadingAttributepair, setLoadingAttributepair] = React.useState<string>(AppLoadingStates[0])
    const [attributepairs, setAttributepairs] = React.useState<Attributepair[]>([])

    const [selectedValue, setSelectedValue] = React.useState<any>()
    const [selectedKey, setSelectedKey] = React.useState<any>()

    const { id } = useParams()
    const { attributeId } = useParams()
    const navigate = useNavigate()

    React.useEffect(() => {
        const getCustomer = async () => {
            const result = await getData("/customers/" + id)
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setCustomer(result.dataValues)
            setLoading(AppLoadingStates[200].default)
        }

        getCustomer()
    }, [])

    React.useEffect(() => {
        const getAttribute = async () => {
            const result = await getData("/attributes/single/" + attributeId)
            if (!result) return setLoadingAttribute(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoadingAttribute(loadingState)

                return setLoadingAttribute(AppLoadingStates[500])
            }

            setAttribute(result)
            setLoadingAttribute(AppLoadingStates[200].default)
        }

        getAttribute()
    }, [])

    React.useEffect(() => {
        const getValues = async () => {
            let pairs: any
            if(selectedKey) {
                pairs = await getData("/attributepairs/" + selectedKey.value)
            } else {
                pairs = await getData("/attributepairs/" + attribute?.key)
            }

            if (!pairs) return setLoadingAttributepair(AppLoadingStates[500])
            if (isNumber(pairs)) {
                const code: keyof typeof AppLoadingStates = pairs
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoadingAttributepair(loadingState)

                return setLoadingAttributepair(AppLoadingStates[500])
            }


            setAttributepairs(pairs)
            setLoadingAttributepair(AppLoadingStates[200].default)
        }

        if(attribute || selectedKey) getValues()
        
    }, [attribute, selectedKey])

    React.useEffect(() => {
        const getAttributepairs = async () => {
            const result = await getData("/attributepairs")
            if (!result) return setAllLoadingAttributepairs(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setAllLoadingAttributepairs(loadingState)

                return setAllLoadingAttributepairs(AppLoadingStates[500])
            }

            setAllAttributepairs(result)
            setAllLoadingAttributepairs(AppLoadingStates[200].default)
        }

        getAttributepairs()
    }, [])

    const handleDelete = async () => {
        const result = await changeData("/attributes/" + attributeId, "DELETE", {})

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        navigate("/kunden/single/" + id)
    }

    const handleEdit = async () => {
        const publicEdit: any = document.getElementById("publicEdit")
        const textarea: any = document.getElementById("valueEditInput")

        if (!attribute || !(selectedValue || textarea.value)) return alert("Ungültige Eingabe")
        const result = await changeData("/attributes/" + attributeId, "PUT", {
            key: (selectedKey ? selectedKey.value : attribute.key),
            value: (selectedValue ? selectedValue.label : textarea.value),
            public: publicEdit.checked
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        navigate("/kunden/single/" + id)
    }

    return (
        <Page showSidebar={true}>
            {loading === AppLoadingStates[200].default ? (
                <Paragraph font_size="2.3em" font_weight="600">{customer?.name}</Paragraph>
            ) : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}
            <Container margin="2em 0 0 0" display="flex" justify_content="space-between" align_items="flex-start">
                <Widget width="22%">
                    <Paragraph font_size="1em">Eigenschaft Löschen?</Paragraph>
                    {loadingAttribute === AppLoadingStates[200].default ? (
                        <Container display="flex" justify_content="center" align_items="center">
                            <Button classes="btn btn-circle btn-error" margin="2em 0 0 0" onClick={() => handleDelete()}>
                                <MdDelete size={24} />
                            </Button>
                        </Container>
                    ) : (
                        <Paragraph font_size="1em">{loadingAttribute}</Paragraph>
                    )}
                </Widget>
                <Widget width="73%">
                    <Paragraph font_size="1.3em" font_weight="600">Bearbeiten</Paragraph>
                    {loadingAttribute === AppLoadingStates[200].default && loadingAttributepair === AppLoadingStates[200].default && loadingAllAttributepairs === AppLoadingStates[200].default ? (
                        <Container>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Eigenschaft</Paragraph>
                            <Select options={Array.from(new Set(allAttributepairs.map(item => item.key))).map((item) => ({
                                label: item,
                                value: item
                            }))} defaultValue={{ label: attribute!.key, value: attribute!.key}} onChange={(choice) => setSelectedKey(choice)}/>
                            <Paragraph font_size="1em" margin="1.4em 0 0.8em 0">Wert</Paragraph>
                            {(attributepairs[0] && attributepairs[0].value === "") ? (
                                <>
                                    {attributepairs.length > 1 ? (
                                        <>
                                            <Paragraph font_size="0.8em" color="gray" margin="0.3em 0 0.6em 0">Bitte nur ein Feld ausfüllen</Paragraph>
                                            <Container display="flex" justify_content="space-between" align_items="flex-start">
                                                <textarea id="valueEditInput" placeholder="Wert..." style={{
                                                    padding: "0.4em",
                                                    borderRadius: "10px",
                                                    border: "1px solid gray",
                                                    width: "62%",
                                                    height: "9vh"
                                                }} />
                                                <Select placeholder="Wert..." onChange={(choice) => setSelectedValue(choice)} options={Array.from(attributepairs).map((item) => ({
                                                    label: item.value,
                                                    value: item.id
                                                }))} />
                                            </Container>
                                        </>
                                    ) : (
                                        <textarea id="valueEditInput" placeholder="Wert..." style={{
                                            padding: "0.4em",
                                            borderRadius: "10px",
                                            border: "1px solid gray",
                                            width: "100%",
                                            height: "9vh"
                                        }} />
                                    )}
                                </>
                            ) : (
                                <Select placeholder="Wert..." onChange={(choice) => setSelectedValue(choice)} options={Array.from(attributepairs).map((item) => ({
                                    label: item.value,
                                    value: item.id
                                }))} />
                            )}
                            <div className="form-control mt-4">
                                <label className="label cursor-pointer">
                                    <Paragraph font_size="1em">Für Kunde sichtbar</Paragraph>
                                    <input type="checkbox" className="checkbox" id="publicEdit" />
                                </label>
                            </div>
                            <Button classes="btn btn-accent" margin="1.3em 0 0 0" onClick={() => handleEdit()}>Editieren</Button>
                        </Container>
                    ) : (
                        <Paragraph font_size="1em">{loadingAttribute}</Paragraph>
                    )}
                </Widget>
            </Container>
        </Page>
    )
}
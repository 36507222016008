import React from "react"

interface props {
    children: React.ReactNode
    padding?: string
    margin?: string
    width?: string
    height?: string
    border?: string
    cursor?: string
    position?: any
    borderRadius?: string
    display?: string
    justify_content?: string
    align_items?: string
    overflow?: string
    overflowy?: any
    max_height?: string
    background?: string
    flex_direction?: any
}

export const Widget = ({children, padding, margin, width, height, border, cursor, position, overflow, display, justify_content, align_items, overflowy, max_height, background, flex_direction}: props) => {

    return (
        <section className={background ? "" : "bg-base-200"} style={{
            margin: margin,
            width: width,
            height: height,
            border: border,
            cursor: cursor,
            position: position,
            borderRadius: "15px",
            overflow: overflow,
            background: background
        }}>

            <div style={{
                padding: (padding ?? "1em"),
                display: display,
                justifyContent: justify_content,
                alignItems: align_items,
                overflowY: overflowy,
                maxHeight: max_height,
                flexDirection: flex_direction,
            }}>
                {children}
            </div>
        </section>

    )
}
import { Container } from "../components/container/Container"
import { Input } from "../components/input/Input"
import { Paragraph } from "../components/paragraph/Paragraph"

interface FormContainerProps {
    label: string
    width?: string
    id?: string
}

export const FormContainer: React.FC<FormContainerProps> = ({label, width, id}) => {
    return (
        <Container margin="0.9em 0 0 0" width={width ?? "100%"}>
            <Paragraph font_size="1.1em" margin="0 0 0.8em 0" font_weight="600">{label}</Paragraph>
            <Input placeholder={label + "..."} padding="1em" height="55px" borderRadius="15px" width="100%" id={id}/>
        </Container>
    )
}
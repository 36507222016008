import React from "react"
import { Button } from "../../../../components/button/Button"
import { Container } from "../../../../components/container/Container"
import { Modal } from "../../../../components/modal/Modal"
import { Paragraph } from "../../../../components/paragraph/Paragraph"
import { FormContainer } from "../../../../helper/GenerateFormInput"
import { changeData, getData } from "../../../../helper/Utils"
import { isNumber } from "../../../../helper/ConvertToStrings"
import { Attribute } from "../../../../interfaces/Attribute"
import { ModalAttribute } from "./Attribute"
import { AppLoadingStates } from "../../../../data/AppLoadingStates"
import { Attributepair } from "../../../../interfaces/Attributepair"
import Select from 'react-select'
import { Input } from "../../../../components/input/Input"
import { Stage } from "../../../../interfaces/Stage"

interface props {
    id: number
}

export const AttributeModal = ({ id }: props) => {

    const [extraAttributes, setExtraAttributes] = React.useState<Attribute[]>([]);
    const [loading, setLoading] = React.useState<string>(AppLoadingStates[0])
    const [pairs, setPairs] = React.useState<Attributepair[]>([])

    // need these for select inputs
    const [keys, setKeys] = React.useState<any[]>([])
    const [values, setValues] = React.useState<any[]>([])
    const [selectedKey, setSelectedKey] = React.useState<any>()
    const [selectedValue, setSelectedValue] = React.useState<any>()

    // true = add value
    // false = add stage
    const [showModalOptions, setShowModalOptions] = React.useState(true)

    const [stages, setStages] = React.useState<Stage[]>([])
    const [stagesLoading, setStagesLoading] = React.useState<string>(AppLoadingStates[0])
    const [selectedStage, setSelectedStage] = React.useState<any>()

    React.useEffect(() => {
        const getPairs = async () => {
            const result = await getData("/attributepairs")
            if (!result) return setLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setLoading(loadingState)

                return setLoading(AppLoadingStates[500])
            }

            setPairs(result)

            // need to eliminate keys which occur twice or more
            const uniqueKeysSet = new Set();
            result.forEach((item: any) => {
                uniqueKeysSet.add(item.key);
            });

            setKeys(Array.from(uniqueKeysSet).map((uniqueKey) => ({
                label: uniqueKey,
                value: uniqueKey
            })));
            setLoading(AppLoadingStates[200].default)
        }

        getPairs()
    }, [])

    React.useEffect(() => {
        const getStages = async () => {
            const result = await getData("/stages")
            if (!result) return setStagesLoading(AppLoadingStates[500])

            if (isNumber(result)) {
                const code: keyof typeof AppLoadingStates = result
                const loadingState = AppLoadingStates[code]

                // should be always true
                if (typeof loadingState === "string") return setStagesLoading(loadingState)

                return setStagesLoading(AppLoadingStates[500])
            }

            setStages(result)
            setStagesLoading(AppLoadingStates[200].default)
        }

        getStages()
    }, [])

    const addExtraAttribute = () => {
        const val: any = document.getElementById("valueModalInput")
        const checked: any = document.getElementById("public")
        const date: any = document.getElementById("datePicker")

        if (!((selectedKey || val.value) || selectedValue)) return alert("Bite fügen sie etwas ein")

        const newAttribute: Attribute = { "key": selectedKey.value, "value": (selectedValue ? selectedValue.value : val.value), "public": checked.checked, "customer_id": id, date: (date.value ?? "") }
        const updatedAttributes = [...extraAttributes, newAttribute]
        setExtraAttributes(updatedAttributes)
    }

    const handleSave = async () => {
        const result = await changeData("/attributes/" + id, "POST", extraAttributes)
        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")

        window.location.reload()
    }

    const handleKeyChange = (choice: any) => {
        setSelectedKey(choice)
        setSelectedValue(null)
        const filtered = pairs.filter((item) => item.key === choice.value)
        setValues(filtered.map((item) => ({
            value: item.value,
            label: item.value
        })))
    }

    const handleAddStage = async () => {
        const date: any = document.getElementById("datePicker")
        if (!selectedStage) return alert("Ungültige Eingabe")
        const result = await changeData("/customers/stage", "POST", {
            customerid: id,
            stageid: selectedStage.value,
            date: (date.value ?? "")
        })

        if (!result || isNumber(result)) return alert("Ein Fehler ist aufgetreten")
        window.location.reload()
    }

    return (
        <Modal headline="Eigenschaften hinzufügen" id="customerAttributes" closeButtonText="Speichern & Schließen" onClick={() => handleSave()}>
            {loading === AppLoadingStates[200].default ? (
                <Container margin="1em" border_radius="10px" classes="bg-white">
                    <Container display="flex" margin="1em 0 1em 0" cursor="pointer">
                        <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_left_radius="10px" height="45px" classes={showModalOptions ? "bg-white" : "bg-base-300"} onClick={() => setShowModalOptions(true)}>
                            <Paragraph font_size="1em">Hinzufügen</Paragraph>
                        </Container>
                        <Container width="50%" display="flex" justify_content="center" align_items="center" border_top_right_radius="10px" height="45px" classes={showModalOptions ? "bg-base-300" : "bg-white"} onClick={() => setShowModalOptions(false)}>
                            <Paragraph font_size="1em">Stage zuweisen</Paragraph>
                        </Container>
                    </Container>

                    <Container padding="1.1em">
                        {showModalOptions ? (
                            <>
                                <Container margin="1.4em 0 0 0">
                                    <Paragraph font_size="1em" margin="0 0 0.6em 0">Eigenschaft</Paragraph>
                                    <Select placeholder="Attribut" options={keys} onChange={handleKeyChange} />
                                    <Paragraph font_size="1em" margin="1em 0 0 0">Wert</Paragraph>
                                    {(values[0] && values[0].value === "") ? (
                                        <>
                                            {values.length > 1 ? (
                                                <>
                                                    <Paragraph font_size="0.8em" color="gray" margin="0.3em 0 0.6em 0">Bitte nur ein Feld ausfüllen</Paragraph>
                                                    <Container display="flex" justify_content="space-between" align_items="flex-start">
                                                        <textarea id="valueModalInput" placeholder="Wert..." style={{
                                                            padding: "0.4em",
                                                            borderRadius: "10px",
                                                            border: "1px solid gray",
                                                            width: "62%",
                                                            height: "9vh"
                                                        }} />
                                                        <Select placeholder="Attribut" options={values.slice(1)} onChange={(choice) => setSelectedValue(choice)} />
                                                    </Container>
                                                </>
                                            ) : (
                                                <textarea id="valueModalInput" placeholder="Wert..." style={{
                                                    padding: "0.4em",
                                                    borderRadius: "10px",
                                                    border: "1px solid gray",
                                                    width: "100%",
                                                    height: "9vh"
                                                }} />
                                            )}
                                        </>

                                    ) : (
                                        <Select placeholder="Attribut" options={values} onChange={(choice) => setSelectedValue(choice)} value={selectedValue} />
                                    )}
                                    <Paragraph font_size="1em" margin="1em 0 0.6em 0">Datum (Optional)</Paragraph>
                                    <Input type="date" padding="0.4em" border="1px solid gray" borderRadius="10px" placeholder="Datum..." width="100%" id="datePicker" />

                                    <div className="form-control mt-3">
                                        <label className="label cursor-pointer">
                                            <Paragraph font_size="1em">Für Kunde sichtbar</Paragraph>
                                            <input type="checkbox" className="checkbox" id="public" />
                                        </label>
                                    </div>
                                    <Container margin="1em 0 0 0">
                                        <Button classes="btn btn-md btn-primary" font_weight="600" onClick={() => addExtraAttribute()}>Hinzufügen</Button>
                                    </Container>
                                </Container>
                                <Container margin="1.5em 0 0 0">
                                    <Paragraph font_size="1.1em">Hinzugefügte Attribute: </Paragraph>
                                    <Container max_height="15vh" overflow="auto" margin="0.8em 0 0 0" border_radius="10px" background="#fff" padding="0.7em">
                                        {extraAttributes.map((item, index) => (
                                            <ModalAttribute data={item} key={index} />
                                        ))}
                                    </Container>
                                </Container>
                            </>
                        ) : (
                            <Container>
                                <Paragraph font_size="1em" margin="1em 0 0.7em 0">Stage</Paragraph>
                                <Select options={stages.map((item) => ({
                                    label: item.name,
                                    value: item.id
                                }))} placeholder="Stage..." onChange={(choice) => setSelectedStage(choice)} />
                                <Paragraph font_size="1em" margin="1em 0 0.6em 0">Datum (Optional)</Paragraph>
                                <Input type="date" padding="0.4em" border="1px solid gray" borderRadius="10px" placeholder="Datum..." width="100%" id="datePicker" />
                                <Button classes="btn btn-accent" onClick={() => handleAddStage()} margin="2em 0 0 0">Hinzufügen</Button>
                            </Container>
                        )}
                    </Container>
                </Container>
            ) : (
                <Paragraph font_size="1em">{loading}</Paragraph>
            )}
        </Modal>
    )
}
interface props {
    size: string
}

export const Loading = ({size}: props) => {

    const classes = `loading loading-spinner loading-${size}`

    return (
        <span className={classes}></span>
    )
}
import { TableColumn } from "../components/table/Table";

export interface Report {
    name: string
    description: string
    type: string
    customer?: string
    updated_at: string
}

// map to inferface, needed for table

export const reportColumns: TableColumn<Report>[] = [
    { key: 'name', label: 'Berichtname' },
    { key: 'type', label: 'Art' },
    { key: 'description', label: 'Beschreibung' },
    { key: 'customer', label: 'Kunde' }
];

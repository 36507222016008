import React, { Children } from "react";

interface props {
    children: React.ReactNode
    font_size: string
    margin?: string
    color?: string
    font_weight?: string
    text_align?: any
    cursor?: string
    textdecoration?: string
    width?: string
    onClick?: () => void
    whitespace?: any
    overflow?: any
    textoverflow?: any
    flex?: string
}

export const Paragraph = ({children, font_size, margin, color, font_weight, text_align, textdecoration, cursor, onClick, width, whitespace, overflow, textoverflow, flex}: props) => {

    return (
        <p style={{
            fontSize: font_size,
            margin: margin,
            color: color,
            fontWeight: font_weight,
            textAlign: text_align,
            cursor: cursor, 
            textDecoration: textdecoration,
            width: width,
            whiteSpace: whitespace,
            overflow: overflow,
            textOverflow: textoverflow,
            flex: flex
        }} onClick={onClick}>
            {children}
        </p>
    )
}